import React, {useContext, useEffect} from "react";
import { database } from "./local-database-library/library";
import blogStyle from '../blogstyle.module.css'
import h1Style from "../h1Style.module.css";
// import BlogArticle from "../../USER/grandArticleBackg";
import { Grandheader, Category, BlogArticle, customizedColorContext, authorNameContext } from "../grandPage";
import Picture from "../picture";
import TheLatest from "../latest";
import {Banner, Banner1, Banner2, Banner3, Banner4, Banner5} from '../adsterra';
// import TheLatest from "../latest";
import primate from '../blogPics/Primate-Ayodele.jpg';
import bm from '../blogPics/BM.png';
//***************************** */
const HiddenBanner1 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'right'}}>{Banner1()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner4 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner4()}</div>
        </div>
    )
}

//****************************************************** */
const HiddenBanner2 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner2()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner5 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'right'}}>{Banner5()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner3 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner3()}</div>
        </div>
    )
}
//*************************** */
let blogads1 = {
    picture: '',
    ads: <HiddenBanner/>,
    title: '',
    link: '',
    country: '',
    topic: '',
    description: ''
}
let blogads2 = {
    picture: '',
    ads: <HiddenBanner3/>,
    title: '',
    link: '',
    country: '',
    topic: '',
    description: ''
}
//**********************f********************************** */
export let ElijahVsEcowas = {
    picture: primate,
    link: 'http://yorubaindependence.com/local/elijah-ayodele-vs-ecowas',
    title: 'Primate Elijah Ayodele comments on Africa Union and ECOWAS committee',
    country: 'Nigeria',
    topic: 'Elijah Ayodele',
    description: 'The clergyman advice to African leaders - The Africa continent need outstanding, selfless leaders'
}
// console.log(ElijahVsEcowas.description.toString().length);
let newest = {
    textAlign: "center",
    color: 'white', 
    textDecoration: 'underline',
    backgroundColor: 'black'
}
let relatedStyle = {
    textAlign: "left",
    color: 'black', 
    textDecoration: 'underline'
}
export const ElijahVsEcowasVsAyodele = () => {
    document.title = "Primate Elijah Ayodele comments on Africa Union and ECOWAS";
    let myMeta = document.createElement('head');
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/55f80087554f1f6b35b39fff65cdb1d5/invoke.js"></script>)
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/9a7e05a8cf5d02916b651253645f6a4f/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/2c48ef1628d6ef1513a522dbad7f282c/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/4827e2f30fe5038127b59b85d910f884/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/8aafcb2b37ff278cbaa3e31f3851cbe6/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/6323653324574e72042ed351bc8075cd/invoke.js"></script>)
    
    // myMeta.innerHTML = (<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8706129627033538"
    // crossorigin="anonymous"></script>);
    myMeta.innerHTML = (<meta name='description' content='ECOWAS and AU slack policies and the necessariness to come up with new, better commitments'></meta>);
    let NEWESTLength = 3;
    //************************************************************************** */
    let {authorName} = useContext(authorNameContext);
    let {customizedColor} = useContext(customizedColorContext);
    //***************************************************************************** */
    let globalLatest = database.global_database.slice(0, 3);
    let localLatest = database.local_database.slice(0, 5);
    let latestArticle = globalLatest.concat(localLatest).filter(item => item.link !== ElijahVsEcowas.link);
    //*********************************************************
    let uselessBlogAdsIndices = [];
    for (let i = 0; i < NEWESTLength; i++) {
        uselessBlogAdsIndices.push(i);
    }
    let usefulBlogAdsIndices = [];
    while (usefulBlogAdsIndices.length < 3) {
        let randomIndices = Math.random() * 10;
        let roundedRandomIndices = Math.round(randomIndices);
        if (roundedRandomIndices >= NEWESTLength) {
            usefulBlogAdsIndices.push(NEWESTLength - 1);
        }
        else {
            usefulBlogAdsIndices.push(uselessBlogAdsIndices[roundedRandomIndices]);
        }
    }
    console.log(uselessBlogAdsIndices + ' useless Blogads indices');
    console.log(usefulBlogAdsIndices + ' useful Blogads indices');
    //********************************************************* */

    latestArticle.splice(usefulBlogAdsIndices[0], 0, blogads1);
    latestArticle.splice(usefulBlogAdsIndices[1], 0, blogads2);
    //**************************************************************************** */
    //**************************************************************************** */
    let globalRelated = database.global_database;
    let localRelated = database.local_database;
    let Articles = globalRelated.concat(localRelated);
    let related1 = Articles.filter(i => { 
        return i.title.includes('Ecowas')});
    let related2 = Articles.filter(i => { 
        return i.title.includes('AU')});
    let related3 = Articles.filter(i => {
        return i.title.includes('Primate Ayodele Elijah')});
    let relatedArticle = related1.concat(related2).concat(related3);
    //********************** */
    let removeDuplicates = relatedArticle.filter((item, index, array) => array.indexOf(item) === index); // this algorithm works in two ways: returns original items without duplicates or returns duplicates/excesses depending on !==/===
    let similarArticles  = removeDuplicates.filter(i => i !== ElijahVsEcowas);
    
    //*************************test the CSS variables******************************************** */
    
    return (
        <>
            <header>
            <Grandheader mySearch= {['']} theShadow= '1px 1px 8px white inset'  border= '1px solid black' background= "white" logoType= {bm}/>
            <Category cateColor= 'black' input1= "Home" input2= "&#62;" input3= "Politics"/>
            <HiddenBanner1/>
            <div id= {h1Style.grandGrid}>
                <h1 id= {h1Style.grandH1}><b>Primate Elijah Ayodele comments on Africa Union and ECOWAS committee, and the terrible danger of the new rise of terrorist groups in Africa</b></h1>
                <p id= {h1Style.grandH5}>ECOWAS and AU slack policies and the necessariness to come up with new, better commitments</p>
            </div>
            <HiddenBanner5/>
            
            <Category cateColor= 'rgb(117, 117, 117)' input1= "BY" input2= {authorName} input3= "CREATED 3/11/23"/>
            </header>
            <BlogArticle>
            <section>
                <main>
                <HiddenBanner4/>
                    <Picture horizonVertic= {[['block', primate, 'Primate Elijah Ayodele'], ['none', '', ''], ['block', customizedColor]]}/>
                    <article>
                        <section>
                        <p className= {blogStyle.blogP}>
                        Evangelical Spiritual Church general overseer Primate Elijah Ayodele forsee the rise of new terrorist militants gatherings in Africa. 
                        The priest uncovered the ECOWAS responsibility and failure to push the Africa mainland ahead of standard and individuals to a superior lifestyle, and ineptitude to over toss terrorism warfare in Africa, on their watch, as terrorism oppression eat some parts of Africa.
                        </p>
                        </section>
                        <section>
                        <p className= {blogStyle.blogP}>
                        He discredits ECOWAS (as they lead the landmass), to the reason for undevelopment, Africa's ghettos, in view of awful policies, that further drive the mainland into bondage, lack of infrastructure, poverty, disease etc.
                        </p>
                        </section>
                        <HiddenBanner2/>
                        <section>
                        <p className= {blogStyle.blogP}>
                        He subsequently informed the Economic Community of West African States, ECOWAS, with respect to West African States, to battle arising terrorists gatherings.
                        He encouraged the African Union (AU) and ECOWAS not to disregard individuals, knowing the circumstance issues in Africa, they ought to work their best to achieve a good dwelling place for the continent, and encouraged every countries to have a bound together (closeness), to advance the travel industry among every territories of the continent.
                        </p>
                        </section>
                        <section>
                        <p className= {blogStyle.blogP}>
                        Primate Elijah Ayodele exposed Africa leaders who made every countries in Africa at the level where they are, as too being used and loved to remain long in power position, corrupt, not to have seen the consideration,  improvement and creation of nations.
                        He said 'AU and ECOWAS are not doing the best.' African leaders drove Africa where it is today.
                        </p>
                        </section>
                    </article>
                </main>
            </section>
            <section>
                    <aside>
                        <p id= {blogStyle.bigType} style= {newest} className= {blogStyle.blogP}><b><em>Newest</em></b></p>
                        <p id= {blogStyle.smallType} style= {relatedStyle} className= {blogStyle.blogP}><b><em>NEWEST ARTICLES</em></b></p>
                    
                        <TheLatest automateLatest= {[
                        [[NEWESTLength + 2, 'horizontal'], latestArticle.map(i => i.picture), latestArticle.map(i => i.title), latestArticle.map(i => i.link), 'block', latestArticle.map(i => i.ads)],
                        [[''], [''], [''], 'none', latestArticle.map(i => i.ads)],
                        ['rgb(118, 118, 228)', '']]}
                        />
                        
                    </aside>
            </section>
            <section>
                 {/* Similar articles */}
                    <p style= {relatedStyle} className= {blogStyle.blogP}><b><em>SIMILAR ARTICLES</em></b></p>
                    <TheLatest automateLatest= {[
                    [[similarArticles.length, 'horizontal'], similarArticles.map(i => i.picture),similarArticles.map(i => i.title), similarArticles.map(i => i.link), 'block', similarArticles.map(i => i.ads)],
                    [[''], [''], [''], 'none', similarArticles.map(i => i.ads)],
                    ['rgb(118, 118, 228)', '']]}
                    />
            </section>
            </BlogArticle>
        </>
    )
 } 