import React from "react";
import minitabNote from "./minitabnote.module.css";


const MinitabNotecontent = (props) => {
    return (
        <div id= {minitabNote.gridmininote}>
                <div className= {minitabNote.flexmininote}>
                    <div id= {minitabNote.note}>
                    <a href= {props.link} target= '_self'> 
                        <span className= {minitabNote.header}>{props.children[0]}</span>
                        <p className= {minitabNote.text}>{props.children[1]}</p>
                    </a>
                    </div>
                    <div style= {{backgroundImage: `url(${props.img})`}} id= {minitabNote.image}></div>
                </div>
            </div>
    )
}
export default MinitabNotecontent;