import React from "react";
// import ReactDOM from "react-dom/client";
import ReactDOM from "react-dom";
import LocalPolitics from "./local";
import GlobalPolitics from "./global";
import ErrB from "./ErrBb";
import About from "./about";
import Contact from "./contact";
import Privacy from "./privacy";
import {LandingPage} from "./grandPage";
import routeX from "./feed image links";
import { CanadaAidSupport } from "./posts/Canada reserves $152m";
import { MamzaFGNGunmi } from "./posts/mamza and Gunmi";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { NigerDeltawikefabura } from "./posts/Ijaw country-Fabura-wike";
import { NLCprotestVsImo } from "./posts/NLC protest and Imo gov";
import { KgCharlesVsKenyaVsApology } from "./posts/king charles apology kenyan";
import { AutonomyInNgByOisa } from "./posts/autonomyInNgByOlisa";
import { HouseOfRepAndYacht } from "./posts/houseOfRep-yacht";
import { GermanyVsColonialVsTanzania } from "./posts/GermanyVsTanzania";
import { NgDebtIncrease } from "./posts/NgGovtDept";
import { ElijahVsEcowasVsAyodele } from "./posts/ElijahAyodeleVsEcowas";
import { RwandaVsVisaFree } from "./posts/RwandaVsVisafree";
import { IgbohoVsNorthernConsusGroupVsUltimatum } from "./posts/FulaniAndSunday";
import { USVsLodgingVsSafety } from "./posts/USAAdvisesOnLodging";
import { PutinVsTreatyVsUS } from "./posts/PutinAndNuclearTreaty";
import { DonaldVsIsraelVsimmigrant } from "./posts/DonaldJVsIsraelHaters";
import { UktravelAdvisoryVsNg } from "./posts/UktravelAdvisoryImoKogi";
import { CanadaAdvisoryVsNig } from "./posts/canadaTravelAdvisory";
import { NorthKoreaShutConsulates } from "./posts/northKoreaShtEmbassy";
import { KyivHit52Days } from "./posts/kyivHitSince 52days";
import { TinubuAttendsJAESSummit } from "./posts/TinubuattendsJAES";
import { AloyEjimakorVsKanuVsNGN } from "./posts/MNKstillheldIllegally";
import { NigeriaRestructureSeyi } from "./posts/NigeriaNeedRestructureSeyiMakin";
import { AdelekeVsStateJudge } from "./posts/adelekeVsStateJudge";
import { JoeAndMarijuana } from "./posts/joe biden and marijuana";
import { BRGIEandmiddlebeltForce } from "./posts/BRGIEANDMiddlebelt";
import { IranAndIsraelAirstrike } from "./posts/top iranian officer killed";
import { IsraelEmbassyExplosion } from "./posts/IsraelEmbassyAttacked";
import { IranClaimOct7vsHamas } from "./posts/iran claim oct 7";
import { MNKAndOwenAndCourt } from "./posts/MNK and Owen Nwokolo";
import { RussiaEmbassyInBurkina } from "./posts/Russia reopen embassy in burkina";
import { FranceDeploy90000 } from "./posts/francefestivesecurity";
import { TurkeyAndTerrorists } from "./posts/turkeyarrestedsuspects";
import { RussiaIntensifyWar } from "./posts/ukraine russia war continue";
import { KosovoVisafreeCountry } from "./posts/kosovoVisafree";
import { SamAdeyemiOnChannels } from "./posts/SamAdeyemionChannels";
import { FranceClosesEmbassy } from "./posts/franceShutitsEmbassy";
import { TrumpMigrationPolicies } from "./posts/donaldTrumpmigrationpolicy";
import { UNToSanctionIranHouthis } from "./posts/USwantIranSanctHousthi";
import { RussiaNeedCombatants } from "./posts/RussiaNeedCombatants";
import { WissamalTawilDead } from "./posts/Hezbolla major killed";
import { PatUtomiOnNigeria } from "./posts/Pat Utomi and Nigeria Not working";
import { HouthisFiredMissles } from "./posts/houthis hit US ship";
import { NigeriansSufferingSharia } from "./posts/Nigerians suffering sharia";
import { HouthisTerrorGroupByUS } from "./posts/houthis terrorist group";
import { TheconstitutionOlisa } from "./posts/restructure ng Olisa 1999 constitution";
import { The21stCYNDAC } from "./posts/Restructure Ng CYNDAC";
import { AdewoleAdebayoInsecurity } from "./posts/Adewole Adebayo insecurity";
import { BidenCallPalestineSovereign } from "./posts/palestine sovereignity";
import { SupremeAndCTC} from "./posts/supreme court delay CTC";
import { SanwooluAndpension} from "./posts/lagosPensionIncrease";
import { SupremeReleaseCTC} from "./posts/supreme court release kanu ctc";
import {RussiaPlaneCrashed} from "./posts/russia plane crashed killed ukriainian";
import {EUTwoStates} from "./posts/EU two states";
import {GaniAdamsRestructureNg} from "./posts/true federalism gani";
import {UNRWA12Members} from "./posts/allegation on uNRWA";
import {SeyiMakindeOnStatePolice} from "./posts/insecurity solution - makinde";
import {InconsiderateKings} from "./posts/inconsiderate Kings";
import {IranDoesNotBackAllies} from "./posts/iran has no backing";
import {IsraelStrikesRafah} from "./posts/israel attack rafah";
import {TrumpOnDefenceBill} from "./posts/trump advises NATO countries defence";
import {Iran45thAnnivasary} from "./posts/iran mark 45 annivasry";
import {IraqSeeksMilitarySupport} from "./posts/iraq allie with us";
import {OshiomholeAndTinubuAdministration} from "./posts/nigerian suffering by lukman";
import {LukmanQueryTinubuOnhardship} from "./posts/osiomhole on buhari policy";
import {BayeroAdvisesRemi} from "./posts/hardship by bayaro";
import {UKUSDeathlyThrows} from "./posts/UK and US dealthy throws in Hodeida";
import {JoeBidenAndBorderSecurity} from "./posts/Joe Biden and border.js";
import {NigeriaGlobalRightsP} from "./posts/Nigeria world rights rating.js";
import {TerroristsInRussiaChurches} from "./posts/terrorists attack russia.js";
import {USAConsidersGunBan} from "./posts/USA considers gun ban.js";
import {NASSNLCNILDSVsMinimumP} from "./posts/NLC NASS NILDS on minimum pay.js";
import {StatePoliceBodeGeorge} from "./posts/state police by Bode George.js";
const Blog = () => {
    return (
        <BrowserRouter>
            <Routes>
            {/* <Route exact path= "/myList" element= {MyRSS}/> */}
                {routeX}
                <Route exact path= "/" element= {<ErrB theError= "The landing page"><LandingPage/></ErrB>}/>
                <Route exact path= "/local" element= {<ErrB theError= "The local page"><LocalPolitics/></ErrB>}/>
                <Route exact path= "/global" element= {<ErrB theError= "The global page"><GlobalPolitics/></ErrB>}/>
                <Route exact path= "/about" element= {<ErrB theError= "The about page"><About/></ErrB>}/>
                <Route exact path= "/contact" element= {<ErrB theError= "The contact page"><Contact/></ErrB>}/>
                <Route exact path= "/privacy" element= {<ErrB theError= "The privacy page"><Privacy/></ErrB>}/>
                <Route exact path= "/local/State-police-needed-by-bode-george" element= {<ErrB theError= "State-police-needed-by-bode-george"><StatePoliceBodeGeorge/></ErrB>}/>
                <Route exact path= "/global/USA-considers-gun-ban" element= {<ErrB theError= "USA-considers-gun-ban"><USAConsidersGunBan/></ErrB>}/>
                <Route exact path= "/local/NASS-NLC-NILDS-form-coalition-on-minimum-wage-and-labour-law-reforms" element= {<ErrB theError= "NASS-NLC-NILDS-form-coalition-on-minimum-wage-and-labour-law-reforms"><NASSNLCNILDSVsMinimumP/></ErrB>}/>
                <Route exact path= "/global/terrorists-in-russia-invade-churches" element= {<ErrB theError= "terrorists-in-russia-invade-churches"><TerroristsInRussiaChurches/></ErrB>}/>
                <Route exact path= "/local/Nigeria-global-rating-2024" element= {<ErrB theError= "Nigeria-global-rating-2024"><NigeriaGlobalRightsP/></ErrB>}/>
                <Route exact path= "/global/Joe-biden-makes-new-policy-on-border-security" element= {<ErrB theError= "Joe-biden-makes-new-policy-on-border-security"><JoeBidenAndBorderSecurity/></ErrB>}/>
                <Route exact path= "/global/US-and-UK-joint-airstrike-killed-16-people-in-Hodeida-yemen" element= {<ErrB theError= "US-and-UK-joint-airstrike-killed-16-people-in-Hodeida-yemen"><UKUSDeathlyThrows/></ErrB>}/>
                <Route exact path= "/local/Aminu-Ado-Bayero-advises-remi-tinubu" element= {<ErrB theError= "Aminu-Ado-Bayero-advises-remi-tinubu"><BayeroAdvisesRemi/></ErrB>}/>
                <Route exact path= "/local/Salihu-Lukman-query-president-Bola-Ahmed-Tinubu-on-Nigerian-hardship" element= {<ErrB theError= "Salihu-Lukman-query-president-Bola-Ahmed-Tinubu-on-Nigerian-hardship"><LukmanQueryTinubuOnhardship/></ErrB>}/>
                <Route exact path= "/local/president-Tinubu-has-the-will-to-do-the-unusual-Oshiomhole" element= {<ErrB theError= "president-Tinubu-has-the-will-to-do-the-unusual-Oshiomhole"><OshiomholeAndTinubuAdministration/></ErrB>}/>
                <Route exact path= "/global/iraq-coalition-on-military-support" element= {<ErrB theError= "iraq-coalition-on-military-support"><IraqSeeksMilitarySupport/></ErrB>}/>
                <Route exact path= "/global/iran-celebrates-45th-anniversary" element= {<ErrB theError= "iran-celebrates-45th-anniversary"><Iran45thAnnivasary/></ErrB>}/>
                <Route exact path= "/global/trump-urge-NATO-countries-on-defence-bill" element= {<ErrB theError= "trump-urge-NATO-countries-on-defence-bill"><TrumpOnDefenceBill/></ErrB>}/>
                <Route exact path= "/global/Israel-start-threat-on-rafah" element= {<ErrB theError= "Israel-start-threat-on-rafah"><IsraelStrikesRafah/></ErrB>}/>
                <Route exact path= "/global/Iran-does-not-command-its-allied-forces" element= {<ErrB theError= "Iran-does-not-command-its-allied-forces"><IranDoesNotBackAllies/></ErrB>}/>
                <Route exact path= "/local/inconsiderate-kings-make-south-west-insecure" element= {<ErrB theError= "inconsiderate-kings-make-south-west-insecure"><InconsiderateKings/></ErrB>}/>
                <Route exact path= "/local/state-police-will-solve-insecurity-challenge" element= {<ErrB theError= "state-police-will-solve-insecurity-challenge"><SeyiMakindeOnStatePolice/></ErrB>}/>
                <Route exact path= "/global/UN-allegations-on-12-UNRWA-members-on-corruption" element= {<ErrB theError= "UN-allegations-on-12-UNRWA-members-on-corruption"><UNRWA12Members/></ErrB>}/>
                <Route exact path= "/local/Restructure-Nigeria-to-true-federalism-Gani-Adams" element= {<ErrB theError= "Restructure-Nigeria-to-true-federalism-Gani-Adams"><GaniAdamsRestructureNg/></ErrB>}/>
                <Route exact path= "/global/Eu-recognize-palestine-a-sovereign-nation" element= {<ErrB theError= "Eu-recognize-palestine-a-sovereign-nation"><EUTwoStates/></ErrB>}/>
                <Route exact path= "/global/ukrainain-missle-shot-down-russia-plane" element= {<ErrB theError= "ukrainain-missle-shot-down-russia-plane"><RussiaPlaneCrashed/></ErrB>}/>
                <Route exact path= "/local/court-release-CTC-for-high-court-proceedings" element= {<ErrB theError= "court-release-CTC-for-high-court-proceedings"><SupremeReleaseCTC/></ErrB>}/>
                <Route exact path= "/local/lagos-government-approve-pension-increase" element= {<ErrB theError= "local/lagos-government-approve-pension-increase"><SanwooluAndpension/></ErrB>}/>
                <Route exact path= "/local/supreme-court-won't-release-CTC-for-Aloy-Ejimakor" element= {<ErrB theError= "supreme-court-won't-release-CTC-for-Aloy-Ejimakor"><SupremeAndCTC/></ErrB>}/>
                <Route exact path= "/global/joe-biden-call-for-the-palestine-sovereignity" element= {<ErrB theError= "joe-biden-call-for-the-palestine-sovereignity"><BidenCallPalestineSovereign/></ErrB>}/>
                <Route exact path= "/local/ADEWOLE_ADEBAYO-comments-on-rising-insecurity-in-nigeria" element= {<ErrB theError= "ADEWOLE_ADEBAYO-comments-on-rising-insecurity-in-nigeria"><AdewoleAdebayoInsecurity/></ErrB>}/>
                <Route exact path= "/local/CYNDAC-want-true-federalism-in-nigeria" element= {<ErrB theError= "CYNDAC-want-true-federalism-in-nigeria"><The21stCYNDAC/></ErrB>}/>
                <Route exact path= "/local/remove-the-1999-constitution-olisa-agbakoba" element= {<ErrB theError= "remove-the-1999-constitution-olisa-agbakoba"><TheconstitutionOlisa/></ErrB>}/>
                <Route exact path= "/global/US-to-designate-houthis-terror-group" element= {<ErrB theError= "US-to-designate-houthis-terror-group"><HouthisTerrorGroupByUS/></ErrB>}/>
                <Route exact path= "/local/Nigerians-are-suffering-Supreme-Council-for-Shariah-in-Nigeria" element= {<ErrB theError= "Nigerians-are-suffering-Supreme-Council-for-Shari’ah-in-Nigeria"><NigeriansSufferingSharia/></ErrB>}/>
                <Route exact path= "/global/houthis-fired-missles" element= {<ErrB theError= "houthis-fired-missles"><HouthisFiredMissles/></ErrB>}/>
                <Route exact path= "/local/Pat-utomi-on-arise-tv" element= {<ErrB theError= "Pat-utomi-on-arise-tv"><PatUtomiOnNigeria/></ErrB>}/>
                <Route exact path= "/global/Wissam-al-Tawil-is-dead" element= {<ErrB theError= "Wissam-al-Tawil-is-dead"><WissamalTawilDead/></ErrB>}/>
                <Route exact path= "/global/Russia-offers-citizenship-to-combatants" element= {<ErrB theError= "Russia-offers-citizenship-to-combatants"><RussiaNeedCombatants/></ErrB>}/>
                <Route exact path= "/global/UN-condemned-the-threats-to-international-trade-and-commerce-in-the-red-ocean" element= {<ErrB theError= "UN-condemned-the-threats-to-international-trade-and-commerce-in-the-red-ocean"><UNToSanctionIranHouthis/></ErrB>}/>
                <Route exact path= "/global/Donald-J-Trump-advocates-for-better-immigration-policies" element= {<ErrB theError= "Donald-J-Trump-advocates-for-better-immigration-policies"><TrumpMigrationPolicies/></ErrB>}/>
                <Route exact path= "/global/france-officially-shut-its-embassy-in-Niger" element= {<ErrB theError= "france-officially-shut-its-embassy-in-Niger"><FranceClosesEmbassy/></ErrB>}/>
                <Route exact path= "/local/Sam-Adeyemi-on-channels.tv-on-social-class-differences" element= {<ErrB theError= "Sam-Adeyemi-on-channels.tv-on-social-class-differences"><SamAdeyemiOnChannels/></ErrB>}/>
                <Route exact path= "/global/Kosovo-becomes-a-visafree-country" element= {<ErrB theError= "Kosovo-becomes-a-visafree-country"><KosovoVisafreeCountry/></ErrB>}/>
                <Route exact path= "/global/russia-will-not-tolerate-counter-offense" element= {<ErrB theError= "russia-will-not-tolerate-counter-offense"><RussiaIntensifyWar/></ErrB>}/>
                <Route exact path= "/global/turkey-curb-terrorism-before-2023" element= {<ErrB theError= "turkey-curb-terrorism-before-2023"><TurkeyAndTerrorists/></ErrB>}/>
                <Route exact path= "/global/france-deploy-90000-cops" element= {<ErrB theError= "france-deploy-90000-cops"><FranceDeploy90000/></ErrB>}/>
                <Route exact path= "/global/russia-reopens-embassy-in-burkina-faso" element= {<ErrB theError= "russia-reopens-embassy-in-burkina-faso"><RussiaEmbassyInBurkina/></ErrB>}/>
                <Route exact path= "/local/mazi-Nnamdi-kanu-case-require-a-round-table-negotiation" element= {<ErrB theError= "mazi-Nnamdi-kanu-case-require-a-round-table-negotiation"><MNKAndOwenAndCourt/></ErrB>}/>
                <Route exact path= "/global/iran-claims-responsible-for-the-oct-7-attack" element= {<ErrB theError= "iran-claims-responsible-for-the-oct-7-attack"><IranClaimOct7vsHamas/></ErrB>}/>
                <Route exact path= "/global/explosion-close-to-israel-embassy-india" element= {<ErrB theError= "explosion-close-to-israel-embassy-india"><IsraelEmbassyExplosion/></ErrB>}/>
                <Route exact path= "/global/Seyed-Razi-Mousavi-killed-in-syria" element= {<ErrB theError= "Seyed-Razi-Mousavi-killed-in-syria"><IranAndIsraelAirstrike/></ErrB>}/>
                <Route exact path= "/local/BRGIE-And-middlebelt-Nigeria-Join-forces" element= {<ErrB theError= "BRGIE-And-middlebelt-Nigeria-Join-forces"><BRGIEandmiddlebeltForce/></ErrB>}/>
                <Route exact path= "/global/joe-government-pardon-marijuana-offenders" element= {<ErrB theError= "joe-government-pardon-marijuana-offenders"><JoeAndMarijuana/></ErrB>}/>
                <Route exact path= "/local/Tinubu-attends-the-Joint-Arab-Islamic-Extraordinary-summit" element= {<ErrB theError= "Tinubu-attends-the-Joint-Arab-Islamic-Extraordinary-summit"><TinubuAttendsJAESSummit/></ErrB>}/>
                <Route exact path= "/local/Ademola-Adeleke-suspends-the-state-judge" element= {<ErrB theError= "Ademola-Adeleke-suspends-the-state-judge"><AdelekeVsStateJudge/></ErrB>}/>
                <Route exact path= "/local/nigeria-need-to-restructure-seyi-makinde" element= {<ErrB theError= "nigeria-need-to-restructure-seyi-makinde"><NigeriaRestructureSeyi/></ErrB>}/>
                <Route exact path= "/local/Mazi-Nnamdi-Kanu-held-wrongfully" element= {<ErrB theError= "Mazi-Nnamdi-Kanu-held-wrongfully"><AloyEjimakorVsKanuVsNGN/></ErrB>}/>
                <Route exact path= "/global/north-korea-shutting-down-embassies" element= {<ErrB theError= "north-korea-shutting-down-embassies"><NorthKoreaShutConsulates/></ErrB>}/>
                <Route exact path= "/global/kyiv-has-been-hit" element= {<ErrB theError= "kyiv-has-been-hit"><KyivHit52Days/></ErrB>}/>
                <Route exact path= "/local/canada-travel-advisory-to-citizens-in-nigeria" element= {<ErrB theError= "canada-travel-advisory-to-citizens-in-nigeria"><CanadaAdvisoryVsNig/></ErrB>}/>
                <Route exact path= "/local/uk-advises-citizens-in-nigeria-ahead-gubernatorial-election" element= {<ErrB theError= "uk-advises-citizens-in-nigeria-ahead-gubernatorial-election"><UktravelAdvisoryVsNg/></ErrB>}/>
                <Route exact path= "/global/Donald-trump-support-Israel" element= {<ErrB theError= "Donald-trump-support-Israel"><DonaldVsIsraelVsimmigrant/></ErrB>}/>
                <Route exact path= "/global/Vladmir-putin-cancels-CTBT-treaty" element= {<ErrB theError= "Vladmir-putin-cancels-CTBT-treaty"><PutinVsTreatyVsUS/></ErrB>}/>
                <Route exact path= "/local/US-issue-warn-emergency-info-on-lodging-safety" element= {<ErrB theError= "US-issue-warn-emergency-info-on-lodging-safety"><USVsLodgingVsSafety/></ErrB>}/>
                <Route exact path= "/local/northern-consensus-movement-of-Nigeria-response-to-10days-ultimatum-declared-from-Igboho" element= {<ErrB theError= "northern-consensus-movement-of-Nigeria-response-to-10days-ultimatum-declared-from-Igboho"><IgbohoVsNorthernConsusGroupVsUltimatum/></ErrB>}/>
                <Route exact path= "/global/Rwanda-becomes-visa-free" element= {<ErrB theError= "Rwanda-becomes-visa-free"><RwandaVsVisaFree/></ErrB>}/>
                <Route exact path= "/local/elijah-ayodele-vs-ecowas" element= {<ErrB theError= "elijah-ayodele-vs-ecowas"><ElijahVsEcowasVsAyodele/></ErrB>}/>
                <Route exact path= "/local/Ng-debt-increases-02/11/23" element= {<ErrB theError= "Ng-debt-increases-02/11/23"><NgDebtIncrease/></ErrB>}/>
                <Route exact path= "/global/Germany-begs-tanzanian-colonial-times" element= {<ErrB theError= "Germany-begs-tanzanian-colonial-times"><GermanyVsColonialVsTanzania/></ErrB>}/>
                <Route exact path= "/local/House-Of-Rep-Remove-Yacht" element= {<ErrB theError= "House-Of-Rep-Remove-Yacht"><HouseOfRepAndYacht/></ErrB>}/>
                <Route exact path= "/local/a-prosperous-nigeria-gives-autonomy" element= {<ErrB theError= "a-prosperous-nigeria-gives-autonomy"><AutonomyInNgByOisa/></ErrB>}/>
                <Route exact path= "/global/king-charles-apologies-to-kenyan" element= {<ErrB theError= "king-charles-apologies-to-kenyan"><KgCharlesVsKenyaVsApology/></ErrB>}/>
                <Route exact path= "/local/NLC-protest-imo-captured-Joe-Ajaero" element= {<ErrB theError= "NLC-protest-imo-captured-Joe-Ajaero"><NLCprotestVsImo/></ErrB>}/>
                <Route exact path= "/local/nigerdelta-to-wike-concerned-about-fabura" element= {<ErrB theError= "nigerdelta-to-wike-concerned-about-fabura"><NigerDeltawikefabura/></ErrB>}/>
                <Route exact path= "/local/Stephen-mamza-voice-out-to-fgn" element= {<ErrB theError= "Stephen-mamza-voice-out-to-fgn"><MamzaFGNGunmi/></ErrB>}/>
                <Route exact path= "/local/canada-aid" element= {<ErrB theError= "canada-aid-$18m-to-nigeria-government"><CanadaAidSupport/></ErrB>}/>
            </Routes>
        </BrowserRouter>
    )
}
// ReactDOM.render(<Blog/>, document.getElementById("root"))
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Blog/>);
