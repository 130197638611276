import React, {useContext, useEffect} from "react";
import { database } from "./local-database-library/library";
import blogStyle from '../blogstyle.module.css';
import h1Style from "../h1Style.module.css";
// import BlogArticle from "../../USER/grandArticleBackg";
import { Grandheader, Category, BlogArticle, customizedColorContext, authorNameContext } from "../grandPage";
import Picture from "../picture";
import {Banner, Banner1, Banner2, Banner3, Banner4, Banner5} from '../adsterra';
import TheLatest from "../latest";
import putin from '../blogPics/ftcms_ceb65e87-deb0-4efd-b48e-682d45a26389.jpeg';
import bm from '../blogPics/BM.png';
//***************************** */
const HiddenBanner1 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'right'}}>{Banner1()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner4 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner4()}</div>
        </div>
    )
}

//****************************************************** */
const HiddenBanner2 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner2()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner5 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'right'}}>{Banner5()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner3 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner3()}</div>
        </div>
    )
}
//*************************** */
let blogads1 = {
    picture: '',
    ads: <HiddenBanner/>,
    title: '',
    link: '',
    country: '',
    topic: '',
    description: ''
}
let blogads2 = {
    picture: '',
    ads: <HiddenBanner3/>,
    title: '',
    link: '',
    country: '',
    topic: '',
    description: ''
}
//**********************f********************************** */
export let PutinVsTreaty = {
    picture: putin,
    link: 'http://yorubaindependence.com/global/Vladmir-putin-cancels-CTBT-treaty',
    title: 'Russia pull out of Nuclear weapon treaty, CTBT',
    country: 'Russia',
    topic: 'Vladmir Putin',
    description: 'Russia pull out of Nuclear weapon treaty, CTBT'
}
let newest = {
    textAlign: "center",
    color: 'white', 
    textDecoration: 'underline',
    backgroundColor: 'black'
}
let relatedStyle = {
    textAlign: "left",
    color: 'black', 
    textDecoration: 'underline'
}
export const PutinVsTreatyVsUS = () => {
    document.title = "Russia pull out of Nuclear weapon treaty";
    let myMeta = document.createElement('head');
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/55f80087554f1f6b35b39fff65cdb1d5/invoke.js"></script>)
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/9a7e05a8cf5d02916b651253645f6a4f/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/2c48ef1628d6ef1513a522dbad7f282c/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/4827e2f30fe5038127b59b85d910f884/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/8aafcb2b37ff278cbaa3e31f3851cbe6/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/6323653324574e72042ed351bc8075cd/invoke.js"></script>)
    
    // myMeta.innerHTML = (<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8706129627033538"
    // crossorigin="anonymous"></script>);
    myMeta.innerHTML = (<meta name='description' content='Russia and Nuclear weapon treaty'></meta>);
    let NEWESTLength = 4;
    //************************************************************************** */
    let {authorName} = useContext(authorNameContext);
    let {customizedColor} = useContext(customizedColorContext);
    //***************************************************************************** */
    let globalLatest = database.global_database.slice(0, 3);
    let localLatest = database.local_database.slice(0, 5);
    let latestArticle = globalLatest.concat(localLatest).filter(item => item.link !== PutinVsTreaty.link);
    //*********************************************************
    let uselessBlogAdsIndices = [];
    for (let i = 0; i < NEWESTLength; i++) {
        uselessBlogAdsIndices.push(i);
    }
    let usefulBlogAdsIndices = [];
    while (usefulBlogAdsIndices.length < 3) {
        let randomIndices = Math.random() * 10;
        let roundedRandomIndices = Math.round(randomIndices);
        if (roundedRandomIndices >= NEWESTLength) {
            usefulBlogAdsIndices.push(NEWESTLength - 1);
        }
        else {
            usefulBlogAdsIndices.push(uselessBlogAdsIndices[roundedRandomIndices]);
        }
    }
    console.log(uselessBlogAdsIndices + ' useless Blogads indices');
    console.log(usefulBlogAdsIndices + ' useful Blogads indices');
    //********************************************************* */

    latestArticle.splice(usefulBlogAdsIndices[0], 0, blogads1);
    latestArticle.splice(usefulBlogAdsIndices[1], 0, blogads2);
    //**************************************************************************** */
    let globalRelated = database.global_database;
    let localRelated = database.local_database;
    let Articles = globalRelated.concat(localRelated);
    let related1 = Articles.filter(i => { 
        return i.title.includes('Nuclear weapon')});
    let related2 = Articles.filter(i => { 
        return i.title.includes('CTBT')});
    let related3 = Articles.filter(i => {
        return i.title.includes('Vladimir Putin')});
    let relatedArticle = related1.concat(related2).concat(related3);
    //********************** */
    let removeDuplicates = relatedArticle.filter((item, index, array) => array.indexOf(item) === index); // this algorithm works in two ways: returns original items without duplicates or returns duplicates/excesses depending on !==/===
    let similarArticles  = removeDuplicates.filter(i => i !== PutinVsTreaty);
    
    //*************************test the CSS variables******************************************** */
    let blogHeight = document.querySelector(':root');
    useEffect(() => {
            blogHeight.style.setProperty('--main01', '600px'); //** wearables and co (PL) */
            blogHeight.style.setProperty('--main1', '950px'); //**iphone 4S(P), iphone 5C(PL) */
            blogHeight.style.setProperty('--main02', '1050px'); //**Iphone 4S (L) */
            blogHeight.style.setProperty('--main2', '1190px'); //**8 iphone 6+ 7+(P) */
            blogHeight.style.setProperty('--main023', '1300px'); //**iphone 6S(P),8 iphone X(P) */
            blogHeight.style.setProperty('--main03', '1580px'); //**iphone 6S(L),8 iphone 6+ 7+(L), galaxy S3(L) */
            blogHeight.style.setProperty('--main3', '1280px'); //**Nexus 6 alt(PL), windows phone(P) */
            blogHeight.style.setProperty('--main4', '1300px'); //*galaxy S3(P), galaxy note 3(PL), galaxy S6(PL), nexus 6P(P) **/
            blogHeight.style.setProperty('--main05', '1550px');  //**nexus 6P(L), windows phone(L), ipad mini(P), ipad air(P) */
            blogHeight.style.setProperty('--main5', '1420px'); //**iphone X(L), ipad 3, 4(P), ipad pro 10.5(P), ipad pro 12.9(P), Kindle fire hd 7(P) */
            blogHeight.style.setProperty('--main6', '1405px'); //**galaxy tab 2(P), galaxy tab S(P) , Laptop(PL)*/
            blogHeight.style.setProperty('--main07', '1790px'); //**galaxy tab 2(L), galaxy tab S(L), Nexus 7(P), Nexus 9(P)*/
            blogHeight.style.setProperty('--main7', '2000px'); //**ipad mini(L), ipad air(L), ipad 3, 4(L), ipad pro 10.5(L), ipad pro 12.9(L), Nexus 7(L), Kindle fire hd 7(L), kindle fire hd 8.9(PL)*/
            blogHeight.style.setProperty('--main8', '2700px'); //**Nexus 9(L), Biggest screen(PL) */
    }, [])
    return (
        <>
            <header>
            <Grandheader mySearch= {['']} theShadow= '1px 1px 8px white inset'  border= '1px solid black' background= "white" logoType= {bm}/>
            <Category cateColor= 'black' input1= "Home" input2= "&#62;" input3= "Politics"/>
            <div id= {h1Style.grandGrid}>
                <h1 id= {h1Style.grandH1}><b>Russia pull out of Nuclear weapon treaty, CTBT</b></h1>
                <p id= {h1Style.grandH5}></p>
            </div>
            <HiddenBanner5/>
            <HiddenBanner1/>
           
            <Category cateColor= 'rgb(117, 117, 117)' input1= "BY" input2= {authorName} input3= "CREATED 5/11/23"/>
            </header>
            <BlogArticle>
            <section>
                <main>
                    <Picture horizonVertic= {[['block', putin, 'President of Russia, Vladimir Putin'], ['none', '', ''], ['block', customizedColor]]}/>
                    <article>
                        <section>
                        <p className= {blogStyle.blogP}>
                        President Vladimir Putin make administrative regulations to de-endorse settlement on nuclear weapons use and testing of nuclear weapons.
                        Comprehensive Nuclear Test Ban Treaty (CTBT) boycotts generally, atomic blasts and nuclear weapon test explosion.
                        </p>
                        </section>
                        <HiddenBanner4/>
                        <section>
                        <p className= {blogStyle.blogP}>
                        Russia has taken this action to haul the nation out from the treaty, not on the bases to test atomic weapons or shoot atomic weapons.
                        This choice was accepted to be taken as a result of US disputable treatment of the deal, not in regard to the settlement they have given help and consented to, however the arrangement has not been viewed in a serious way in the following years by, since its development/curation.
                        </p>
                        </section>
                        <section>
                        <p className= {blogStyle.blogP}>
                        Russia has removed their signatory position that entails the utilization of atomic weapons and trial of atomic blasts formally.
                        </p>
                        </section>
                        <HiddenBanner2/>
                        <section>
                        <p className= {blogStyle.blogP}>
                        In any case, this signatory position don't imply that Russia will penetrate utilizing atomic weapons, the nation will anyway chooses if to use it or not.
                        </p>
                        </section>
                    </article>
                    
                </main>
            </section>
            <section>
                    <aside>
                        <p id= {blogStyle.bigType} style= {newest} className= {blogStyle.blogP}><b><em>Newest</em></b></p>
                        <p id= {blogStyle.smallType} style= {relatedStyle} className= {blogStyle.blogP}><b><em>NEWEST ARTICLES</em></b></p>
                    
                        <TheLatest automateLatest= {[
                        [[NEWESTLength + 2, 'horizontal'], latestArticle.map(i => i.picture), latestArticle.map(i => i.title), latestArticle.map(i => i.link), 'block', latestArticle.map(i => i.ads)],
                        [[''], [''], [''], 'none', latestArticle.map(i => i.ads)],
                        ['rgb(118, 118, 228)', '']]}
                        />
                        
                    </aside>
            </section>
            <section>
                 {/* Similar articles */}
                    <p style= {relatedStyle} className= {blogStyle.blogP}><b><em>SIMILAR ARTICLES</em></b></p>
                    <TheLatest automateLatest= {[
                    [[similarArticles.length, 'horizontal'], similarArticles.map(i => i.picture),similarArticles.map(i => i.title), similarArticles.map(i => i.link), 'block', similarArticles.map(i => i.ads)],
                    [[''], [''], [''], 'none', similarArticles.map(i => i.ads)],
                    ['rgb(118, 118, 228)', '']]}
                    />
            </section>
            </BlogArticle>
        </>
    )
 } 