import React from "react";
import blogStyle from './blogstyle.module.css';
import {Grandheader, Category} from "./grandPage";
import bm from './blogPics/BM.png';
import { TheSocial, TheLinking } from "./footer";
import Menu from "./Menu";


//***************************************/
let blogFooterStyle = {
    backgroundColor: 'white',
    color: 'black'
}
//*****************d**********************/
const Contact = () => {
    document.title = "Contact BM";
    let myMeta = document.createElement('head');
    myMeta.innerHTML = (<script type='text/javascript' src='//pl22283200.toprevenuegate.com/64/24/05/64240599871cce71a658981da110d019.js'></script>);
    // myMeta.innerHTML = (<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8706129627033538"
    // crossorigin="anonymous"></script>);
    myMeta.innerHTML = (<meta name='description' content='BM contact page'></meta>);
    //******************************************************** */
    let linkedIn = (
        <img alt="LinkedIn URL" src="https://img.shields.io/twitter/url?label=About&logo=LinkedIn&logoColor=blue&style=social&url=https%3A%2F%2FLinkedIn.io"/>
    )

    let twitter = (
        <img alt="Twitter URL" src="https://img.shields.io/twitter/follow/OlutobiOlusesi?label=Twitter&logo=Twitter&style=social"/>
    )

    let gmail = (
        <img alt="Gmail URL" src="https://img.shields.io/twitter/url?label=Gmail&logo=Gmail&style=social&url=https%3A%2F%2Fgmail.io"/>
    )
    //*************************test the CSS variables******************************************** */
    
    return (
        <>
            <header>
            <Grandheader mySearch= {['']} theShadow= '1px 1px 8px white inset'  border= '1px solid black' background= "white" logoType= {bm}/>
            <Category cateColor= 'black' input1= "Home" input2= "&#62;" input3= "Politics"/>
            </header>
            <section>
                <article>
                    <p className= {blogStyle.blogP}>
                        Contact us here&#160;<a target= '_self' href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=olusesiolutobi@gmail.com&su=SUBJECT&body=BODY"><img alt="Gmail URL" src="https://img.shields.io/twitter/url?label=Gmail&logo=Gmail&style=social&url=https%3A%2F%2Fgmail.io"/></a>&#160;: olasunkanmiesan76@gmail.com
                    </p>
                </article>
            </section>
            <footer>
                <Menu options= {['', '', '']} links= {['', '']} bC= 'white' ne= 'block'/>
                <TheLinking/>
                <TheSocial socialOnOff= 'block' mySocial= {[linkedIn, gmail, twitter]} myTwoStyle= {blogFooterStyle}/>
            </footer>
        </>
    )
} 
export default Contact;