import React from "react";
// import { myDisplay } from "./Thetopheader";
import menuStyle from './menu.module.css';
//*************************************y***********<a href=”javascript:googlefc.callbackQueue.push(googlefc.showRevocationMessage)”>R-ads</a>*********** */

// export function RADS() {
//     let adsHttp = new XMLHttpRequest();
//     adsHttp.open("GET", "googlefc.callbackQueue.push(googlefc.showRevocationMessage)", "async")
//     adsHttp.send();
// }

const Menu = (props) => {
    
    console.log(`menu ${props.ne}`)
    return (
        <div style={{display: props.ne}}>
            <div style={{backgroundColor: props.bC}} className= {menuStyle.menuGrid}>
                <div className= {menuStyle.menuFlex}>
                    <nav className= {menuStyle.specific} id= {menuStyle.space1}></nav>
                    <nav className= {menuStyle.specific}><a href= {props.links[0]} target="_blank">{props.options[0]}</a></nav>
                    <nav  className= {menuStyle.specific}></nav>
                    <nav className= {menuStyle.specific}><a href= {props.links[1]} target="_blank">{props.options[1]}</a></nav>
                    <nav className= {menuStyle.specific} id= {menuStyle.space2}>{props.options[2]}</nav>
                </div>
            </div>
        </div>
        
    )
}

export default Menu;