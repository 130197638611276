import React, {useContext, useEffect} from "react";
import { database } from "./local-database-library/library";
import blogStyle from '../blogstyle.module.css';
import h1Style from "../h1Style.module.css";
// import BlogArticle from "../../USER/grandArticleBackg";
import { Grandheader, Category, BlogArticle, customizedColorContext, authorNameContext } from "../grandPage";
import Picture from "../picture";
import {Banner, Banner1, Banner2, Banner3, Banner4, Banner5} from '../adsterra';
import TheLatest from "../latest";
// import TheLatest from "../latest";
import tinubuInJAES from '../blogPics/IMG_20231112_235722.jpg';
import bm from '../blogPics/BM.png';

//***************************** */
const HiddenBanner1 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'right'}}>{Banner1()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner4 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner4()}</div>
        </div>
    )
}

//****************************************************** */
const HiddenBanner2 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner2()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner5 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'right'}}>{Banner5()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner3 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner3()}</div>
        </div>
    )
}
//*************************** */
let blogads1 = {
    picture: '',
    ads: <HiddenBanner/>,
    title: '',
    link: '',
    country: '',
    topic: '',
    description: ''
}
let blogads2 = {
    picture: '',
    ads: <HiddenBanner3/>,
    title: '',
    link: '',
    country: '',
    topic: '',
    description: ''
}

//**********************f********************************** */
export let TinubuAttendsJAES = {
    picture: tinubuInJAES,
    link: 'http://yorubaindependence.com/local/Tinubu-attends-the-Joint-Arab-Islamic-Extraordinary-summit',
    title: "The Nigerian President , Bola Ahmed Tinubu , in the Joint Arab Islamic Extraordinary summit, November 13, 2023", // space words
    country: 'Nigeria',
    topic: 'Bola Ahmed Tinubu',
    description: 'The Nigerian President in the Joint Arab Islamic Extraordinary summit, November 13, 2023'
} 
let newest = {
    textAlign: "center",
    color: 'white', 
    textDecoration: 'underline',
    backgroundColor: 'black'
}
let relatedStyle = {
    textAlign: "left",
    color: 'black', 
    textDecoration: 'underline'
}
export const TinubuAttendsJAESSummit = () => {
    document.title = "Tinubu in JAES";
    let myMeta = document.createElement('head');
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/55f80087554f1f6b35b39fff65cdb1d5/invoke.js"></script>)
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/9a7e05a8cf5d02916b651253645f6a4f/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/2c48ef1628d6ef1513a522dbad7f282c/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/4827e2f30fe5038127b59b85d910f884/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/8aafcb2b37ff278cbaa3e31f3851cbe6/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/6323653324574e72042ed351bc8075cd/invoke.js"></script>)
    
    // myMeta.innerHTML = (<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8706129627033538"
    // crossorigin="anonymous"></script>);
    myMeta.innerHTML = (<meta name='description' content='The Joint Arab Islamic Extraordinary summit call for Peace to the ongoing Israel - Gaza war'></meta>);
    //************************************************************************** */
    let NEWESTLength = 3;
    //************************************************************************** */
    let {authorName} = useContext(authorNameContext);
    let {customizedColor} = useContext(customizedColorContext);
    //***************************************************************************** */
    let globalLatest = database.global_database.slice(0, 3);
    let localLatest = database.local_database.slice(0, 5);
    let latestArticle = globalLatest.concat(localLatest).filter(item => item.link !== TinubuAttendsJAES.link);
    //*********************************************************
    let uselessBlogAdsIndices = [];
    for (let i = 0; i < NEWESTLength; i++) {
        uselessBlogAdsIndices.push(i);
    }
    let usefulBlogAdsIndices = [];
    while (usefulBlogAdsIndices.length < 3) {
        let randomIndices = Math.random() * 10;
        let roundedRandomIndices = Math.round(randomIndices);
        if (roundedRandomIndices >= NEWESTLength) {
            usefulBlogAdsIndices.push(NEWESTLength - 1);
        }
        else {
            usefulBlogAdsIndices.push(uselessBlogAdsIndices[roundedRandomIndices]);
        }
    }
    console.log(uselessBlogAdsIndices + ' useless Blogads indices');
    console.log(usefulBlogAdsIndices + ' useful Blogads indices');
    //********************************************************* */

    latestArticle.splice(usefulBlogAdsIndices[0], 0, blogads1);
    latestArticle.splice(usefulBlogAdsIndices[1], 0, blogads2);
    //**************************************************************************** */
    let globalRelated = database.global_database;
    let localRelated = database.local_database;
    let Articles = globalRelated.concat(localRelated);
    let related1 = Articles.filter(i => { 
        return i.title.includes('Joint Arab Extraordinary Summit')});
    let related2 = Articles.filter(i => { 
        return i.title.includes('JAES')});
    let related3 = Articles.filter(i => {
        return i.title.includes('Islamic countries')});
    let related4 = Articles.filter(i => {
        return i.title.includes('Islamic country')});
    let relatedArticle = related1.concat(related2).concat(related3).concat(related4);
    //********************** */
    let removeDuplicates = relatedArticle.filter((item, index, array) => array.indexOf(item) === index); // this algorithm works in two ways: returns original items without duplicates or returns duplicates/excesses depending on !==/===
    let similarArticles  = removeDuplicates.filter(i => i !== TinubuAttendsJAES);
    
    //*************************test the CSS variables******************h************************** */
   
    return (
        <>
            <header>
            <Grandheader mySearch= {['']} theShadow= '1px 1px 8px white inset'  border= '1px solid black' background= "white" logoType= {bm}/>
            <Category cateColor= 'black' input1= "Home" input2= "&#62;" input3= "Politics"/>
            
            <div id= {h1Style.grandGrid}>
                <h1 id= {h1Style.grandH1}><b>The Nigerian President, Bola Ahmed Tinubu, in the Joint Arab Islamic Extraordinary summit</b></h1>
                <p id= {h1Style.grandH5}>The Joint Arab Islamic Extraordinary summit calls for Peace to the ongoing Israel - Gaza war</p>
            </div>
            <HiddenBanner5/>
          
            <Category cateColor= 'rgb(117, 117, 117)' input1= "BY" input2= {authorName} input3= "CREATED 12/11/23"/>
            </header>
            <BlogArticle>
            <section>
                <main>
                    <Picture horizonVertic= {[['block', tinubuInJAES, ''], ['none', '', ''], ['block', customizedColor]]}/>
                    <article>
                        <section>
                        <p className= {blogStyle.blogP}>
                        The Nigerian President, Bola Ahmed Tinubu, went to the Joint Arab Islamic Extraordinary summit, which held in Riyad, Saudi Arabia, yesterday November 11.
                        </p>
                        <HiddenBanner1/>
                        </section>
                        <section>
                        <p className= {blogStyle.blogP}>
                        During the program of summit, it was a privilege in one voice for the delegates of Islamic countries all around the world, called for this harmony, to an end in the Israel - Gaza war which has been progressing for some time now since the October 7 assault by Hamas on some Israelis which has caused the genesis of the Conflict prescribed.
                        </p>
                        </section>
                        <HiddenBanner4/>
                        <section>
                        <p className= {blogStyle.blogP}>
                        For the summit, on the side of the Palestine sibling Islamic country, they censured Israel ceaseless military assaults in Gaza, proscribed it, as an atrocity against the Palestinians and approached the UNSC intervention to stop Israeli military tasks in Gaza that has killed over of 11,000 Palestinians, which huge extent that have been killed are kids and ladies, and other different atrocities carried out against the Palestinians like the cutting off of power and removing water supply in the Gaza region. 
                        </p>
                        </section>
                        <section>
                        <p className= {blogStyle.blogP}> 
                        In the highest point, there were likewise grievances about certain countries that were financing and giving stock of ammo into Israel that were being utilized by Israeli Forces. They likewise listed on why Gaza strip (east of Palestine) and West bank ought not be isolated as it is a Palestine state and it has East Al-Quds as it capital.
                        </p>
                        </section>
                        <HiddenBanner2/>
                    </article>
                </main>
            </section>
            <section>
                    <aside>
                        <p id= {blogStyle.bigType} style= {newest} className= {blogStyle.blogP}><b><em>Newest</em></b></p>
                        <p id= {blogStyle.smallType} style= {relatedStyle} className= {blogStyle.blogP}><b><em>NEWEST ARTICLES</em></b></p>
                    
                        <TheLatest automateLatest= {[
                        [[NEWESTLength + 2, 'horizontal'], latestArticle.map(i => i.picture), latestArticle.map(i => i.title), latestArticle.map(i => i.link), 'block', latestArticle.map(i => i.ads)],
                        [[''], [''], [''], 'none', latestArticle.map(i => i.ads)],
                        ['rgb(118, 118, 228)', '']]}
                        />
                        
                    </aside>
            </section>
            <section>
                 {/* Similar articles */}
                    <p style= {relatedStyle} className= {blogStyle.blogP}><b><em>SIMILAR ARTICLES</em></b></p>
                    <TheLatest automateLatest= {[
                    [[similarArticles.length, 'horizontal'], similarArticles.map(i => i.picture),similarArticles.map(i => i.title), similarArticles.map(i => i.link), 'block', similarArticles.map(i => i.ads)],
                    [[''], [''], [''], 'none', similarArticles.map(i => i.ads)],
                    ['rgb(118, 118, 228)', '']]}
                    />
            </section>
            </BlogArticle>
        </>
    )
 } 