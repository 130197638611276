import React from "react";
import latestStyle from "./latest.module.css";
//*********************************************** */
// console.log(props.horizonVertic[0][4] + 'horizon vertic');

// let aLink, inSection, imageDiv, titleP;
//     aLink = document.createElement('a');
//     aLink.target = "_self";
//     aLink.className = latestStyle.latestPic;
//     inSection = document.createElement('section');
//     imageDiv = document.createElement('div');
//     imageDiv.className = latestStyle.horizontal;
//     titleP = document.createComment('p');
//     titleP.className = latestStyle.blockText;
//     aLink.appendChild(inSection).appendChild(imageDiv).appendChild(titleP);
//     let workDiv = document.createElement('div').innerHTML = aLink;
//     document.getElementById('pick').innerHTML = workDiv;
//     console.log(aLink + 'aLink');

const Horizon = (props) => {
    // aLink.href = props.horizonVertic[0][2];
    // aLink.style.display =  props.horizonVertic[0][3];
    // imageDiv.style.backgroundImage = `url(${props.horizonVertic[0][0]})`;
    // titleP.style = props.figColor;
    // titleP.innerHTML = props.horizonVertic[0][1];
   if (props.horizonVertic[0][4] === undefined || props.horizonVertic[0][4] === "undefined") {
        return (
            <a style= {{display: props.horizonVertic[0][3]}} href= {props.horizonVertic[0][2]} className= {latestStyle.latestPic} target= "_self">
            <section>
            <div style= {{backgroundImage: `url(${props.horizonVertic[0][0]})`}} className= {latestStyle.horizontal}></div>
            <p className= {latestStyle.blockText} style= {props.figColor}>{props.horizonVertic[0][1]}</p>
            </section>
            </a>
        )
   }
   else {
       return (
            <div>
            {props.horizonVertic[0][4]}
            </div>
       )
   }
}
//*************i******href= {props.horizonVertic[0][2]}**************/
const Vertic = (props) => {
    if (props.horizonVertic[0][4] === undefined || props.horizonVertic[0][4] === "undefined") {
        return (
            <a style= {{display: props.horizonVertic[0][3]}} className= {latestStyle.latestPic} href= {props.horizonVertic[0][2]} target="_self">
                    {/* <div> */}
                <section>
                <div style={{backgroundImage: `url(${props.horizonVertic[0][0]})`}} className= {latestStyle.vertical}> </div>
                <p className= {latestStyle.blockText1} style= {props.figColor}>{props.horizonVertic[0][1]}</p>
                </section>
                {/* </div> */}
            </a>
        )
   }
   else {
       return (
            <div>
            {props.horizonVertic[0][4]}
            </div>
       )
   }
    
    
}
//***************************************/
const TheLatest = (props) => {
    let figColor = {
        color: props.automateLatest[2][0]
    }
    let latestContain;
    let axis = props.automateLatest[0][0][1];
   
    let n = Array(props.automateLatest[0][0][0]).fill(1);
    console.log(n);
    console.log(props.automateLatest[0][5] + ' horizon vertic');
        latestContain = n.map((item, i, arrayLatest) => {
            if (i < arrayLatest.length & axis === 'horizontal') {
                return (<Horizon horizonVertic= {[[props.automateLatest[0][1][i], props.automateLatest[0][2][i], props.automateLatest[0][3][i], props.automateLatest[0][4], props.automateLatest[0][5][i]]]} figColor= {figColor}/>) // n of iteration, images, text, link, display - horizontal
            } // figcolor, flex direction, grid what?
            else if (i < arrayLatest.length & axis === 'vertical') {
                return (<Vertic horizonVertic= {[[props.automateLatest[1][0][i], props.automateLatest[1][1][i], props.automateLatest[1][2][i], props.automateLatest[1][3], props.automateLatest[0][5][i]]]} figColor= {figColor}/>) // images, text, link, display - vertical 
            }
            
        });
        
    return (
        <section>
            <div id= {latestStyle.unnecessary}>
            <div className= {latestStyle.latestGrid}>
                {/* <div id= {latestStyle.pleaseJustify}> */}
                <div style={{flexDirection: props.automateLatest[2][1]}} id= {latestStyle.latestFlex}>
                    {/* <span></span> */}
                    {latestContain}
                    {/*<span></span>*/}
                </div>
                {/* </div> */}
            </div>
            </div>
        </section>
    )
}
//***********************************s*******
export default TheLatest;
