import React, {useRef, createContext, useReducer} from 'react';
import topStyle from "./Thetopheader.module.css";
import CategoryStyle from "./category.module.css";
import LandingPageStyle from "./LandingPage.module.css";
import SearchTabStyle from "./searchstyle.module.css";
import { database } from "./posts/local-database-library/library";
import navStyles from "./navstyles.module.css";
import h1Style from "./h1Style.module.css";
import backgStyle from "./blogArticle.module.css";
import relatedGridStyle from "./relatedGrid.module.css";
import reducer from "./menuReducerHook";
import MinitabNotecontent from "./minitabnote";
import { TheSocial, TheLinking } from "./footer";
import {NoteTabcontent, NoteTabcontent1} from "./notetab";
// import Padding from "./Home";
import Menu from "./Menu";
import {Banner, Banner1, Banner2, Banner3, Banner4, Banner5} from './adsterra';
import show from './blogPics/pngwing.com.png';
import bm from './blogPics/BM.png';
import hide from './blogPics/hamburger-button-drop-down-list-computer-icons-navigation-bars-and-page-menu-templates-361b393044224cd0f32b688b4e9a1eaa.png';
// import searchIconImage from './search-icon.png';
import 'semantic-ui-css/semantic.min.css';
//***********************************************/

export let authorNameContext = createContext({authorName: "Olusesi Tobi"});
export let customizedColorContext = createContext({customizedColor: "black"});
//*********************************************************************

//********************************************************************************************************************************************* */
let linkedIn = (
    <img alt="LinkedIn URL" src="https://img.shields.io/twitter/url?label=About&logo=LinkedIn&logoColor=blue&style=social&url=https%3A%2F%2FLinkedIn.io"/>
)

let twitter = (
    <img alt="Twitter URL" src="https://img.shields.io/twitter/follow/OlutobiOlusesi?label=Twitter&logo=Twitter&style=social"/>
)

let gmail = (
    <img alt="Gmail URL" src="https://img.shields.io/twitter/url?label=Gmail&logo=Gmail&style=social&url=https%3A%2F%2Fgmail.io"/>
)
//***************************************/
let blogFooterStyle = {
    backgroundColor: 'white',
    color: 'black'
}
//**********************************main and aside grid outline****************************************************************************************************************/
//********************************************A grand page for the blog*************************************************************************************************************/
const BlogArticle = (props) => {
    return (
            <>
                <div style= {{gridTemplateRows: 'auto'}} className= {backgStyle.blogGrig}>
                    <div className= {backgStyle.article}>
                        {props.children[0]}
                    </div>
                    <div className= {backgStyle.latestArticle}>
                    {/* {props.verticalRendering[0]} */}
                    {/* <TheLatest automateLatest= {[
                        [[1, 'horizontal'], ['', ''], ['', 'toloka'], ['', ''], 'block'],
                        [[''], [''], [''], 'none'],
                        ['', '', 'block']]}
                        /> */}
                        {props.children[1]}
                    </div>
                </div>
                {/* <Menu options= {['', '', '']} links= {['', '']} bC= 'white' ne= 'block'/> */}
                {/* <div className= {backgStyle.mobileLatest}>
                {props.children[3]}
                </div> */}
                <div className= {relatedGridStyle.relatedGrid}>
                    {/* <div></div> */}
                    {props.children[2]}
                    {/* {props.verticalRendering[1]} */}
                    {/* <div></div> */}
                </div>
                <footer>
                    <Menu options= {['', '', '']} links= {['', '']} bC= 'white' ne= 'block'/>
                    <TheLinking/>
                    <TheSocial socialOnOff= 'block' mySocial= {[linkedIn, gmail, twitter]} myTwoStyle= {blogFooterStyle}/>
                </footer>
                {/* <div id= "container-55f80087554f1f6b35b39fff65cdb1d5"></div> */}
                {/* {Banner()} */}
            </>
    )
}
//************************************************************************************************ */
const SearchTab = (props) => {
    // null value in input later
    return (
        <>
            <div id= {SearchTabStyle.SearchFlex}>
                <input type='search' onFocus= {props.passedDownSearchValues[1]} onChange= {props.passedDownSearchValues[0]} placeholder='Search here...' id= {SearchTabStyle.search}></input>
                {/* <div id= {SearchTabStyle.searchIcon}><img src= {searchIconImage} width= '20px' height= '20px' alt='search icon'/></div> */}
            </div>
        </>
    )
}
//*************************************the top header for the grand page***************************************************************************************************** */
const Grandheader = (props) => {
    // let {main$blog, change$main$blog} = useContext(Mainblogcontext);
    
    // let local$global = () => {
    //     return change$main$blog((lg) => lg = {blog: <TheLatest automateLatest= {[
    //         [[2, 'horizontal'], [Bs, ty], ['Sunday Igboho will be back from Benin Republic to declare Yoruba Nation Sunday Igboho will be back from Benin Republic to declare Yoruba Nation', 'toloka'], ['', ''], 'block'],
    //         [[''], [''], [''], 'none'],
    //         ['red', 'column', 'block']]}
    //     />})
    // }
        //******************************************/
        let top = {
            backgroundColor: props.background,
            borderBottom: props.border,
            borderTop: props.border,
            boxShadow: props.theShadow
        }
        
        //***********************************************************/
        let count = useRef(1);
        //*************************** */
        function add1() {
            return count.current += 1;
        }
        let d = 'block';
        let i = 'ellipsis vertical icon';
        let d1 = 'none';
        let i1 = 'bars icon';
        let initialState;
        //******************************/
        initialState = {
            clickDisplay: 'none',
            clickIcon: 'bars icon'
        }
        let [newMenuElement, dispatch] = useReducer(reducer, initialState);
        let theNew = newMenuElement.clickDisplay;
        //*******************************************************/
        let showHide = (e) => {
            e.preventDefault();
            add1();
            dispatch({type: (count.current % 2) === 0? true: false, menuD: d, menuI: i, menuD1: d1, menuI1: i1});
            // let passedDownFunction = props.mySearch[1];
            // passedDownFunction();
            // dispatch({type: (count.current % 2) !== 0? false: true, menuD1: d1, menuI1: i1});
        }
        //*************************************************************************/
        // let blockNone = {
        //     display: props.myBlockmyNone,
        //     color: 'white'
        // }
        //************************ */
        console.log(`${count.current} count`);
        console.log(`${newMenuElement.clickDisplay} display`);
        console.log(`${newMenuElement.clickIcon} icon`);
        
    return (
            <> 
                <div style={top} className={topStyle.headerstyle}>
                    <div className= {topStyle.headerflex}>
                        <div className={topStyle.logostyle}>
                            <img alt= 'branch medium logo'src= {props.logoType}></img>
                        </div>
                        <div onClick= {showHide} id= {topStyle.menu}>
                        {(count.current % 2 === 0? <i className= "ellipsis vertical icon"></i> : <i className= "bars icon"></i>)}
                        {(count.current % 2 === 0? <span><img alt= 'menu icon' src= {show}></img></span> : <span><img alt= 'menu icon' src= {hide}></img></span>)}
                        </div>
                    </div>
                </div>
                <Menu options= {['Local', 'Global', props.mySearch[0]]} links= {['http://yorubaindependence.com/local', 'http://yorubaindependence.com/global']} bC= 'black' ne= {theNew}/>
                
            </>
    )
}

//****************************h****u*****category for subsection************************************************************************************************************/
const Category = (props) => {
    let cateColor = {
        color: props.cateColor
    }
    return (
        <div id= {CategoryStyle.myGrid}>
            <div id= {CategoryStyle.myFlex}>
                <h6 style={cateColor} className= {CategoryStyle.space1}>{props.input1}</h6>
                <h6 style={cateColor} className= {CategoryStyle.space2}><i>{props.input2}</i></h6>
                <h6 style={cateColor} className= {CategoryStyle.space2}>{props.input3}</h6>
            </div>
        </div>
    )
}
//***********************************************heading and sub-heading/description ************************************************************************************************* */

const TheH1 = (props) => {
    return (
        <div id= {h1Style.grandGrid}>
            <h1 id= {h1Style.grandH1}><b>{props.children[0]}</b></h1>
            <p id= {h1Style.grandH5}>{props.children[1]}</p>
        </div>
    )
}
const NavigateArticles = (props) => {
    return (
        <section>
            <div id= {navStyles.navigationGrid}>
                <div id= {navStyles.navigationFlex}>
                    <div style= {{color: 'purple'}} className= {navStyles.nav}>{props.display[0]}</div>
                    <div style= {{display: props.display[5], backgroundColor: props.display[7].backgroundC, color: props.display[7].textC}}  onClick= {props.navigation[0]} className= {navStyles.nav}>first</div>
                    <div style= {{display: props.display[1], backgroundColor: props.display[3].backgroundC, color: props.display[3].textC}} onClick= {props.navigation[1]} className= {navStyles.nav}>NEXT</div>
                    <div style= {{display: props.display[2], backgroundColor: props.display[4].backgroundC, color: props.display[4].textC}} onClick= {props.navigation[2]} className= {navStyles.nav}>previous</div>
                    <div style= {{display: props.display[6], backgroundColor: props.display[8].backgroundC, color: props.display[8].textC}} onClick= {props.navigation[3]} className= {navStyles.nav}>last</div>
                </div>
            </div>
        </section>
    )
}
//****************************************************** */
const HiddenBanner1 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'left'}}>{Banner1()}</div>
        </div>
    )
}
//****************************************************** */

const HiddenBanner = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
           <div style= {{textAlign: 'right'}}>{Banner()}</div>
        </div>
    )
}

//****************************************************** */
const HiddenBanner5 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'left'}}>{Banner5()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner3 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'right'}}>{Banner3()}</div>
        </div>
    )
}
//**************************************************
const LandingPage = () => {
        document.title = 'BM Politics News';
        let myMeta = document.createElement('head');
        myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/55f80087554f1f6b35b39fff65cdb1d5/invoke.js"></script>)
        myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/9a7e05a8cf5d02916b651253645f6a4f/invoke.js"></script>);
        myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/2c48ef1628d6ef1513a522dbad7f282c/invoke.js"></script>);
        myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/4827e2f30fe5038127b59b85d910f884/invoke.js"></script>);
        myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/8aafcb2b37ff278cbaa3e31f3851cbe6/invoke.js"></script>);
        myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/6323653324574e72042ed351bc8075cd/invoke.js"></script>)
        // myHead.innerHTML = (<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8706129627033538"
        // crossorigin="anonymous"></script>);
        myMeta.innerHTML = (<meta name='description' content='|politics News|'></meta>);
        //************************************************************************** */
    return (
        <>
            <header>
                <section>
                    <Grandheader mySearch= {['']} theShadow= '1px 1px 8px white inset'  border= '1px solid black' background= "white" logoType= {bm}/>
                    {/* <HiddenBanner4/> */}
                    <Category cateColor= 'black' input1= "Home" input2= "&#62;" input3= "Politics"/>
                </section>
            </header>
            {/* style="overflow-x: hidden; overflow-y: hidden" */}
            <main className= {LandingPageStyle.LandingPageGrid}>
            
            <HiddenBanner1/>
                <section>
                    {/* <Padding/> */}
                    <NoteTabcontent notes= {[
                        database.local_database[0].picture, database.local_database[0].title, database.local_database[0].link,
                        database.local_database[1].title, database.local_database[1].link,
                        database.local_database[2].title, database.local_database[2].link,
                        database.local_database[3].title, database.local_database[3].link, 
                        database.local_database[4].picture, database.local_database[4].title, database.local_database[4].link,
                        database.local_database[5].title, database.local_database[5].link,
                        database.global_database[1].picture, database.global_database[1].title, database.global_database[1].link,
                        database.global_database[2].picture, database.global_database[2].title, database.global_database[2].link,
                        database.global_database[3].title, database.global_database[3].link,
                        '',
                        database.global_database[1].country,
                        database.local_database[2].country,
                        database.local_database[3].country,
                        database.global_database[2].country,
                        database.local_database[5].country,
                        database.global_database[3].country
                    ]               
                    }/>
                    {/* <Padding/> */}
                </section>
                
                <HiddenBanner/>
               
                <section>
                    <MinitabNotecontent img= {database.global_database[0].picture} link= {database.global_database[0].link}>
                        <span>{database.global_database[0].topic}</span>
                        <span>{database.global_database[0].description}</span>
                        {/* 200 char */}
                    </MinitabNotecontent>
                    {/* <Padding/> */}
                </section>
                
                {/* <HiddenBanner2/> */}
                <HiddenBanner5/>
                <section>
                    {/* <Padding/> */}
                    <NoteTabcontent1 notes= {[
                        database.local_database[6].picture, database.local_database[6].title, database.local_database[6].link,
                        database.local_database[7].title, database.local_database[7].link,
                        database.local_database[8].title, database.local_database[8].link,
                        database.local_database[9].title, database.local_database[9].link,
                        database.local_database[10].picture, database.local_database[10].title, database.local_database[10].link,
                        database.local_database[11].title, database.local_database[11].link,
                        database.global_database[4].picture, database.global_database[4].title, database.global_database[4].link,
                        database.global_database[5].picture, database.global_database[5].title, database.global_database[5].link,
                        database.global_database[6].title, database.global_database[6].link,
                        '',
                        database.global_database[4].country,
                        database.local_database[8].country,
                        database.local_database[9].country,
                        database.global_database[5].country,
                        database.local_database[11].country,
                        database.global_database[6].country
                    ]               
                    }/>
                    {/* <Padding/> */}
                </section>
               
                <HiddenBanner3/>
                
            </main>

            <footer>
                <section>
                    <TheLinking/>
                    <TheSocial socialOnOff= 'block' mySocial= {[linkedIn, gmail, twitter]} myTwoStyle= {blogFooterStyle}/>
                </section>
            </footer>
        
        </>
    )
}
//*******************************************************/
export {TheH1, Category, Grandheader, BlogArticle, SearchTab, NavigateArticles, LandingPage}
//******************************************************************************************************************************/
// const GrandBlog = () => {
//     return (
//         <div>
//             <GrandHeader theShadow= '1px 1px 8px rgba(202, 202, 202, 1) inset'  border= '1px solid rgba(202, 202, 202, 1)' background= "whitesmoke" logoType= {bm}/>
//             <Category cateColor= 'black' input1= "Home" input2= "&#62;" input3= "Politics"/>
//             <TheH1>
//                 <span>Hi</span>
//                 <span>BloG</span>
//             </TheH1>
//             <Category cateColor= 'rgb(117, 117, 117)' input1= "BY" input2= "OLUSESI OLUTOBI" input3= "UPDATED 9/09/23"/>
//             <BlogArticle>
//             <Picture horizonVertic= {[['block', Bs, 'Battle Steed'], ['none', '', ''], ['block', 'blue']]}/>
//             <TheLatest automateLatest= {[
//                 [[2, 'horizontal'], [Bs, ty], ['Sunday Igboho will be back from Benin Republic to declare Yoruba Nation Sunday Igboho will be back from Benin Republic to declare Yoruba Nation', 'toloka'], ['', ''], 'block'],
//                 [[''], [''], [''], 'none'],
//                 ['red', 'column', 'block']]}
//             />
            // <TheLatest automateLatest= {[
            //     [[8, 'horizontal'], [Bs, Bs, Bs, Bs, Bs, Bs, Bs, Bs], ['Sunday Igboho', 'from Benin Republic', 'to declare Yoruba',  'Nation Sunday Igboho', 'will be back from Benin Republic', 'yes he will have been made', 'yar old man', 'you are in this job'], ['', '', '', '', '', '', '', ''], 'block'],
            //     [[battle49, battle49, battle49, battle49, battle49, battle49, battle49, battle49], ['Sunday and that Igboho', 'from Benin Republic', 'to declare Yoruba',  'Nation Sunday Igboho', 'will be back from Benin Republic', 'yes he will have been made', 'yar old man', 'you are in this job'], ['', '', '', '', '', '', '', ''], 'none'], 
            //     ['green', '', 'block']]}
            // />
//             </BlogArticle>
//         </div>
//     )
// }

// export default GrandBlog;
