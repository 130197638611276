import React, {useEffect} from "react";
import footerstyle from "./footer.module.css";
import theLinkingStyle from "./thelinking.module.css";
import rss from './blogPics/valid-rss-rogers (1).png';
//********************************ll***************** */



const TheLinking = () => {
    return (
        <>
            <div id= {theLinkingStyle.linkingGrid}>
                <div id= {theLinkingStyle.linkingFlex}>
                <a href= 'http://yorubaindependence.com/about' target= '_self'><div className= {`${theLinkingStyle.s1} ${theLinkingStyle.centre}`}>About</div></a>
                    <div className= {`${theLinkingStyle.s2} ${theLinkingStyle.right}`}>Copyright &copy; 2023</div>
                    <a href= 'http://yorubaindependence.com/privacy' target= '_self'><div className= {`${theLinkingStyle.s3} ${theLinkingStyle.left}`}>Privacy Policy</div></a>
                    <a href= 'http://yorubaindependence.com/contact' target= '_self'><div className= {`${theLinkingStyle.s4} ${theLinkingStyle.right}`}>Contact</div></a>
                    <a href= '' target= '_self'><div className= {`${theLinkingStyle.s5} ${theLinkingStyle.left}`}>Home</div></a>
                    <div className= {`${theLinkingStyle.s6} ${theLinkingStyle.right}`}>All Rights Reserved</div>
                    <a href= 'http://yorubaindependence.com/' target= '_self'><div className= {`${theLinkingStyle.s7} ${theLinkingStyle.left}`}>BMNews</div></a>
                    <a href="http://www.rssboard.org/rss-validator/check.cgi?url=https%3A//yorubaindependence.com/myList.xml"><div className= {`${theLinkingStyle.s8} ${theLinkingStyle.right}`}>RSS feed</div></a>
                </div>
                {/* <div className= {theLinkingStyle.s8}></div><img src={rss} alt="[Valid RSS]" title="Validate my RSS feed" />
                <div className= {theLinkingStyle.s9}></div>
                <div className= {theLinkingStyle.s10}></div>
                <div className= {theLinkingStyle.s11}></div>
                <div className= {theLinkingStyle.s12}></div>
                <div className= {theLinkingStyle.s13}></div>
                <div className= {theLinkingStyle.s14}></div> */}
            </div>
        </>
    )
}

//*************************************************************************************
const TheSocial = (props) => {
    return (
        <div style={{display: props.socialOnOff}}>
            <div style= {props.myTwoStyle} className={footerstyle.footersocial}>
            <address id= {footerstyle.socialflex}>
                <div><a target= '_self' href= 'https://www.linkedin.com/in/branch-medium-3a634716a'><span  id= {footerstyle.tempo1} className= {footerstyle.tempo}>{props.mySocial[0]}</span></a></div>
                <div><a target= '_self' href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=olusesiolutobi@gmail.com&su=SUBJECT&body=BODY"><span id= {footerstyle.tempo2} className= {footerstyle.tempo}>{props.mySocial[1]}</span></a></div>
                <div><a target= '_self' href="https://twitter.com/OlutobiOlusesi?s=09"><span id= {footerstyle.tempo3} className= {footerstyle.tempo}>{props.mySocial[2]}</span></a></div>
            </address>
            </div>
        </div>
    )
}
//***********give a footer shadow*****************************/
// const Footer = (props) => {
//     let t = document.querySelector(':root');
//     console.log(props.positioning[0]);         
//     useEffect(() => {
//         if (props.positioning[0] === 0) {
//             t.style.setProperty('--top1', '0px');
//             t.style.setProperty('--top2', '0px');
//             t.style.setProperty('--top3', '0px');
//             t.style.setProperty('--top4', '0px');
//             t.style.setProperty('--top5', '0px');
//             t.style.setProperty('--top6', '0px');
//             t.style.setProperty('--top7', '0px');
//             t.style.setProperty('--top8', '0px');
//             t.style.setProperty('--top9', '0px');
//             t.style.setProperty('--top10', '0px');
//             t.style.setProperty('--top11', '0px');
//             t.style.setProperty('--top12', '0px');
//             t.style.setProperty('--top13', '0px');
//             t.style.setProperty('--top14', '0px');
//             t.style.setProperty('--top15', '0px');
//             t.style.setProperty('--top16', '0px');
//             t.style.setProperty('--top17', '0px');
//             t.style.setProperty('--top18', '0px');
//             t.style.setProperty('--top19', '0px');
//             t.style.setProperty('--top20', '0px');
//             t.style.setProperty('--top21', '0px');
//             t.style.setProperty('--top22', '0px');
//             t.style.setProperty('--top23', '0px');
//             t.style.setProperty('--top24', '0px');
//             t.style.setProperty('--top25', '0px');
//             t.style.setProperty('--top26', '0px');
//             t.style.setProperty('--top27', '0px');
//             t.style.setProperty('--top28', '0px');
//             t.style.setProperty('--top29', '0px');
//             t.style.setProperty('--top30', '0px');
//             t.style.setProperty('--top31', '0px');
//         }
        
//         else {
//             t.style.setProperty('--top1', '281px');
//             t.style.setProperty('--top2', '218px');
//             t.style.setProperty('--top3', '480px');
//             t.style.setProperty('--top4', '320px');
//             t.style.setProperty('--top5', '568px');
//             t.style.setProperty('--top6', '667px');
//             t.style.setProperty('--top7', '375px');
//             t.style.setProperty('--top8', '736px');
//             t.style.setProperty('--top9', '414px');
//             t.style.setProperty('--top10', '812px');
//             t.style.setProperty('--top11', '640px');
//             t.style.setProperty('--top12', '360px');
//             t.style.setProperty('--top13', '732px');
//             t.style.setProperty('--top14', '412px');
//             t.style.setProperty('--top15', '592px');
//             t.style.setProperty('--top16', '800px');
//             t.style.setProperty('--top17', '1024px');
//             t.style.setProperty('--top18', '768px');
//             t.style.setProperty('--top19', '1180px');
//             t.style.setProperty('--top20', '820px');
//             t.style.setProperty('--top21', '1112px');
//             t.style.setProperty('--top22', '834px');
//             t.style.setProperty('--top23', '1366px');
//             t.style.setProperty('--top24', '1280px');
//             t.style.setProperty('--top25', '960px');
//             t.style.setProperty('--top26', '600px');
//             t.style.setProperty('--top27', '1200px');
//             t.style.setProperty('--top28', '2048px');
//             t.style.setProperty('--top29', '1536px');
//             t.style.setProperty('--top30', '800px');
//             t.style.setProperty('--top31', '1600px');
//         }
//     }, [])
//     let spanIt = {
//         color: `${props.blackness}`
//     }
//     let marginalTop = {
//         marginTop: `${props.marginalT}px`,
//         backgroundColor: `${props.background}`
//     }
//     let theTwoStyle = {
//         backgroundColor: `${props.background}`,
//         color: `${props.blackness}`
//     }
//     return (
//         <footer>
//             <div style={marginalTop} className= {footerstyle.container}>
//                 <div style= {theTwoStyle} className= {footerstyle.footerdetails}>
//                     <div style= {{borderRightColor: props.blackness}} id= {footerstyle.pad}>
//                             <span style= {spanIt}>{props.langText[0]}</span>
//                             <hr style={{borderColor: props.blackness, backgroundColor: props.blackness}}/>
//                             <span className= {footerstyle.linktext}><a style= {spanIt} target= '_self' href= 'https://medium.com/@branchM1'>{props.langText[1]}</a> </span>
//                             <span className= {footerstyle.linktext}><a style= {spanIt} target= '_self' href= 'https://dev.to/branchm1'>{props.langText[2]}</a></span>
//                             <span className= {footerstyle.linktext}><a style= {spanIt} target= '_self' href= 'https://developereconomics.net?member_id=QiEGJNss&lang=en&locale=en'>{props.langText[3]}</a></span>
//                             <span className= {footerstyle.linktext}><a style= {spanIt} target= '_self' href= 'https://github.com/BranchMedium'>{props.langText[4]}</a></span>
//                             <span className= {footerstyle.linktext}><a style= {spanIt} target= '_self' href= 'https://developers.google.com/profile/u/me/dashboard'>{props.langText[5]}</a></span>
//                             <span className= {footerstyle.linktext}><a style= {spanIt} target= '_self' href= 'https://leetcode.com/BranchMedium/'>{props.langText[6]}</a></span>
//                             <hr style={{borderColor: props.blackness, backgroundColor: props.blackness}}/>
//                             <span style= {spanIt}>{props.langText[7]}</span>
//                             <hr style={{borderColor: props.blackness, backgroundColor: props.blackness}}/>
//                             <span className= {footerstyle.linktext}><a style= {spanIt} target= '_self' href= 'https://branchmediumlevelmarketing.com/privacy'>{props.langText[9]}</a></span>
//                             <span style= {spanIt} className= {footerstyle.linktext}>{props.langText[8]}</span>
//                     </div>
//                 </div> 
                
//             </div>
//         {/****************************************/}

//         <TheSocial socialOnOff= {props.onOff} mySocial= {[props.socialLang[0], props.socialLang[1], props.socialLang[2]]} myTwoStyle={theTwoStyle}/>
//         </footer>
//     )
// }
export {TheSocial, TheLinking};
