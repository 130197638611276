import React, {useContext, useEffect} from "react";
import { database } from "./local-database-library/library";
import blogStyle from '../blogstyle.module.css';
import h1Style from "../h1Style.module.css";
// import BlogArticle from "../../USER/grandArticleBackg";
import { Grandheader, Category, BlogArticle, customizedColorContext, authorNameContext } from "../grandPage";
import Picture from "../picture";
import {Banner, Banner1, Banner2, Banner3, Banner4, Banner5} from '../adsterra';
import TheLatest from "../latest";
// import TheLatest from "../latest";
import trump from '../blogPics/GettyImages-1705984999.jpg';
import bm from '../blogPics/BM.png';

//**************************** */
const HiddenBanner1 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'right'}}>{Banner1()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner4 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner4()}</div>
        </div>
    )
}

//****************************************************** */
const HiddenBanner2 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner2()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner5 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'right'}}>{Banner5()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner3 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner3()}</div>
        </div>
    )
}
//*************************** */
let blogads1 = {
    picture: '',
    ads: <HiddenBanner/>,
    title: '',
    link: '',
    country: '',
    topic: '',
    description: ''
}
let blogads2 = {
    picture: '',
    ads: <HiddenBanner3/>,
    title: '',
    link: '',
    country: '',
    topic: '',
    description: ''
}

//**********************f********************************** */
export let TrumpOnDefence = {
    picture: trump,
    link: 'http://yorubaindependence.com/global/trump-urge-NATO-countries-on-defence-bill',
    title: 'Donald Trump tells NATO countries "increase spending on defense bill"', // space words
    country: 'USA',
    topic: 'Donald Trump',
    description: '"America will not support countries not paying their bill" - Trump'
} 
// console.log(r_battlesteed.description.toString().length);
let newest = {
    textAlign: "center",
    color: 'white', 
    textDecoration: 'underline',
    backgroundColor: 'black'
}
let relatedStyle = {
    textAlign: "left",
    color: 'black', 
    textDecoration: 'underline'
}
export const TrumpOnDefenceBill = () => {
    document.title = 'Donald Trump tells NATO countries "increase spending on defense bill"';
    let myMeta = document.createElement('head');
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/55f80087554f1f6b35b39fff65cdb1d5/invoke.js"></script>)
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/9a7e05a8cf5d02916b651253645f6a4f/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/2c48ef1628d6ef1513a522dbad7f282c/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/4827e2f30fe5038127b59b85d910f884/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/8aafcb2b37ff278cbaa3e31f3851cbe6/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/6323653324574e72042ed351bc8075cd/invoke.js"></script>)
    // myMeta.innerHTML = (<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8706129627033538"
    // crossorigin="anonymous"></script>);
    myMeta.innerHTML = (<meta name='description' content='"America will not support countries not paying their bill" - Trump'></meta>);
    //************************************************************************** */
    let NEWESTLength = 4;
    //************************************************************************** */
    let {authorName} = useContext(authorNameContext);
    let {customizedColor} = useContext(customizedColorContext);
    //***************************************************************************** */
    let globalLatest = database.global_database.slice(0, 3);
    let localLatest = database.local_database.slice(0, 5);
    let latestArticle = globalLatest.concat(localLatest).filter(item => item.link !== TrumpOnDefence.link);
    //*********************************************************
    let uselessBlogAdsIndices = [];
    for (let i = 0; i < NEWESTLength; i++) {
        uselessBlogAdsIndices.push(i);
    }
    let usefulBlogAdsIndices = [];
    while (usefulBlogAdsIndices.length < 3) {
        let randomIndices = Math.random() * 10;
        let roundedRandomIndices = Math.round(randomIndices);
        if (roundedRandomIndices >= NEWESTLength) {
            usefulBlogAdsIndices.push(NEWESTLength - 1);
        }
        else {
            usefulBlogAdsIndices.push(uselessBlogAdsIndices[roundedRandomIndices]);
        }
    }
    latestArticle.splice(usefulBlogAdsIndices[0], 0, blogads1);
    latestArticle.splice(usefulBlogAdsIndices[1], 0, blogads2);
    //**************************************************************************** */
    
    let globalRelated = database.global_database;
    let localRelated = database.local_database;
    let Articles = globalRelated.concat(localRelated);
    let related1 = Articles.filter(i => { 
        return i.title.includes('Defence bill')});
    let related2 = Articles.filter(i => { 
        return i.title.includes('NATO defence bill')});
    let related3 = Articles.filter(i => {
        return i.title.includes('NATO national security')});
    let related4 = Articles.filter(i => {
        return i.title.includes('NATO defence')});
    let relatedArticle = related1.concat(related2).concat(related3).concat(related4);
    //********************** */
    let removeDuplicates = relatedArticle.filter((item, index, array) => array.indexOf(item) === index); // this algorithm works in two ways: returns original items without duplicates or returns duplicates/excesses depending on !==/===
    let similarArticles  = removeDuplicates.filter(i => i !== TrumpOnDefence);
    
    //*************************test the CSS variables******************************************** */
    return (
        <>
            <header>
            <Grandheader mySearch= {['']} theShadow= '1px 1px 8px white inset'  border= '1px solid black' background= "white" logoType= {bm}/>
            <Category cateColor= 'black' input1= "Home" input2= "&#62;" input3= "Politics"/>
           
           
            <div id= {h1Style.grandGrid}>
                <h1 id= {h1Style.grandH1}><b>Donald Trump tells NATO countries "increase spending on defense bill"</b></h1>
                <p id= {h1Style.grandH5}>"America will not support countries not paying their bill" - Trump</p>
            </div>
           
            <HiddenBanner5/>
            <Category cateColor= 'rgb(117, 117, 117)' input1= "BY" input2= {authorName} input3= "CREATED 12/02/24"/>
            </header>
            <BlogArticle>
            <section>
                <main>
                <HiddenBanner4/>
                    <Picture horizonVertic= {[['block', trump, ''], ['none', '', ''], ['block', customizedColor]]}/>
                    <article>
                   
                        <p className= {blogStyle.blogP}>
                        Trump has advised it ally countries not to be slack on defense mechanism of their country, they should all increase spending on defense bill for any flighty attack.
                        </p>
                        <p className= {blogStyle.blogP}>
                        He has encouraged Russia to take - on it opponent on any NATO country which had refused to spend more on defence. He also said, any NATO country which had refused to cooporate it spending on defense bill, and is not doing much similar to the USA preparedness on defence and lavishness on it defence bill, he said, America should not protect such countries and will not support such those countries despite being part of NATO, despite America being part of NATO.
                        </p>
                        <HiddenBanner1/>
                        <p className= {blogStyle.blogP}>
                        "You don't pay your bills, you get no protection. It's very simple."
                        </p>
                        <p className= {blogStyle.blogP}>
                        "I said, 'You didn't pay, you're delinquent,'" Trump said. "No, I would not protect you. In fact, I would encourage them to do whatever the hell they want."
                        </p>
                        <HiddenBanner2/>
                        <p className= {blogStyle.blogP}>
                        Against Trump's comment, there have been criticisms from his country and from NATO Secretary-General Jens Stoltenbergon, on his remarks for supporting Russia and letting down NATO allies.
                        </p>
                        <p className= {blogStyle.blogP}>
                        Jens Stoltenbergon on this note responded, this comment put out a risk to the national security of NATO countries. He said in a statement. "Any suggestion that allies will not defend each other undermines all of our security."

                        </p>
                       

                    </article>
                </main>
            </section>
            <section>
                    <aside>
                        <p id= {blogStyle.bigType} style= {newest} className= {blogStyle.blogP}><b><em>Newest</em></b></p>
                        <p id= {blogStyle.smallType} style= {relatedStyle} className= {blogStyle.blogP}><b><em>NEWEST ARTICLES</em></b></p>
                    
                        <TheLatest automateLatest= {[
                        [[NEWESTLength + 2, 'horizontal'], latestArticle.map(i => i.picture), latestArticle.map(i => i.title), latestArticle.map(i => i.link), 'block', latestArticle.map(i => i.ads)],
                        [[''], [''], [''], 'none', latestArticle.map(i => i.ads)],
                        ['rgb(118, 118, 228)', '']]}
                        />
                        
                    </aside>
            </section>
            <section>
                 {/* Similar articles */}
                    <p style= {relatedStyle} className= {blogStyle.blogP}><b><em>SIMILAR ARTICLES</em></b></p>
                    <TheLatest automateLatest= {[
                    [[similarArticles.length, 'horizontal'], similarArticles.map(i => i.picture),similarArticles.map(i => i.title), similarArticles.map(i => i.link), 'block', similarArticles.map(i => i.ads)],
                    [[''], [''], [''], 'none', similarArticles.map(i => i.ads)],
                    ['rgb(118, 118, 228)', '']]}
                    />
            </section>
            </BlogArticle>
        </>
    )
 } 