import React from "react";
import Notetab from "./notetab.module.css";
import {Banner, Banner1, Banner2, Banner3, Banner4, Banner5} from './adsterra';
import Picture from "./picture";
import Kremlin from './blogPics/ftcms_ceb65e87-deb0-4efd-b48e-682d45a26389.jpeg';
//******************************************* */
const Subtitle = (props) => {
    return (
        
            <a target= '_self' href= {`${props.subtitle[1]}`}>
                <div className= {Notetab.subtitletab}>{props.subtitle[0]}</div>
            </a>
        
    )
}
//*********************************************/
const SubtitleTab = (props) => {
    return (
        <div id= {Notetab.flexsubtitletab}>
            <Subtitle subtitle= {[props.data[0], props.data[1]]}/>
        </div>
    )
}
//***************************************************t*********************************************************************************************** */

const DescriptiveSubtitle = (props) => {
    return (
        <a target= '_self' href= {`${props.describeSubtitle[1]}`}>
            <div className= {Notetab.describesubtitletabFlex}>
                    <div style= {{color: 'rgb(35, 3, 150)'}} id= {Notetab.description}><span className= {Notetab.indicator}>.</span>{props.describeSubtitle[2]}</div>
                    <div id= {Notetab.title}> {props.describeSubtitle[0]}</div>
            </div>
        </a>
    )
}

//*****************************************/
const DescriptiveSubtitleTab = (props) => {
    return (
        <div id= {Notetab.flexdescribesubtitletab}>
            <DescriptiveSubtitle describeSubtitle= {[props.data[0], props.data[1], props.data[2]]}/>
        </div>
    )
}
//******************************************************************************************************************************************************* */
const PicTab = (props) => {
    return (
        <div id= {Notetab.flexpictab}>
            <div style= {{backgroundImage: `url(${props.homepic})`}} className= {Notetab.picturepictab}>
            </div>
            {/* <SubtitleTab/> */}
            {props.children}
        </div>
    )
}
//************************************************************************************************************************************************************* */
const HiddenBanner4 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner4()}</div>
        </div>
    )
}
//*******************************************************/
const HiddenBanner2 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner2()}</div>
        </div>
    )
}
//****************************************************** */


const NoteTabcontent = (props) => {
    return (
        <div id= {Notetab.gridnotetab}>
            <div style= {{flexDirection: `${props.notes[22]}`}} className= {Notetab.flexnotetab}>
            <section>
            <PicTab homepic= {props.notes[0]}><SubtitleTab data= {[props.notes[1], props.notes[2]]}/></PicTab>
            </section>
            <section>
            <HiddenBanner2/>
            </section>
            <section>
            <PicTab homepic= {props.notes[14]}><DescriptiveSubtitleTab data= {[props.notes[15], props.notes[16], props.notes[23]]}/></PicTab>
            </section>
            {/* <section>
            <div style= {{justifyContent: 'center'}}>{Banner1()}</div>
            </section> */}
            <section>
            <Subtitle subtitle ={[props.notes[3], props.notes[4]]}/>
            </section>
            <section>
            <DescriptiveSubtitle describeSubtitle= {[props.notes[5], props.notes[6], props.notes[24]]}/>
            </section>
            {/* <section>
            <div style= {{justifyContent: 'center'}}>{Banner2()}</div>
            </section> */}
            {/* <section>
            <Picture horizonVertic= {[['block', Kremlin, ''], ['none', '', ''], ['block', 'red']]}/>
            </section> */}
            <section>
            <DescriptiveSubtitle describeSubtitle= {[props.notes[7], props.notes[8], props.notes[25]]}/>
            </section>
            <section>
            <PicTab homepic= {props.notes[17]}><DescriptiveSubtitleTab data= {[props.notes[18], props.notes[19], props.notes[26]]}/></PicTab>
            </section>
            {/* <section>
            <div style= {{justifyContent: 'center'}}>{Banner1()}</div>
            </section> */}
            <section>
            <PicTab homepic= {props.notes[9]}><SubtitleTab data= {[props.notes[10], props.notes[11]]}/></PicTab>
            </section>
            <section>
            <DescriptiveSubtitle describeSubtitle= {[props.notes[12], props.notes[13], props.notes[27]]}/>
            </section>
            <section>
            <DescriptiveSubtitle describeSubtitle= {[props.notes[20], props.notes[21], props.notes[28]]}/>
            </section>
            {/* <section>
            <div style= {{justifyContent: 'center'}}>{Banner2()}</div>
            </section> */}
            </div>
        </div>
    )
}
//********************************* */
const NoteTabcontent1 = (props) => {
    return (
        <div id= {Notetab.gridnotetab}>
            <div style= {{flexDirection: `${props.notes[22]}`}} className= {Notetab.flexnotetab}>
                <section>
                <HiddenBanner4/>
                </section>
                <section>
                <PicTab homepic= {props.notes[0]}><SubtitleTab data= {[props.notes[1], props.notes[2]]}/></PicTab>
                </section>
                <section>
                <PicTab homepic= {props.notes[17]}><DescriptiveSubtitleTab data= {[props.notes[18], props.notes[19], props.notes[26]]}/></PicTab>
                </section>
                {/* <section>
                <div style= {{justifyContent: 'center'}}>{Banner1()}</div>
                </section> */}
                <section>
                <PicTab homepic= {props.notes[9]}><SubtitleTab data= {[props.notes[10], props.notes[11]]}/></PicTab>
                </section>
                <section>
                <DescriptiveSubtitle describeSubtitle= {[props.notes[12], props.notes[13], props.notes[27]]}/>
                </section>
                <section>
                <DescriptiveSubtitle describeSubtitle= {[props.notes[20], props.notes[21], props.notes[28]]}/>
                </section>
                <section>
                <PicTab homepic= {props.notes[14]}><DescriptiveSubtitleTab data= {[props.notes[15], props.notes[16], props.notes[23]]}/></PicTab>
                </section>
                {/* <section>
                <div style= {{justifyContent: 'center'}}>{Banner1()}</div>
                </section> */}
                <section>
                <Subtitle subtitle ={[props.notes[3], props.notes[4]]}/>
                </section>
                <section>
                <DescriptiveSubtitle describeSubtitle= {[props.notes[5], props.notes[6], props.notes[24]]}/>
                </section>
                {/* <section>
                <div style= {{justifyContent: 'center'}}>{Banner2()}</div>
                </section> */}
                {/* <section>
                <Picture horizonVertic= {[['block', Kremlin, ''], ['none', '', ''], ['block', 'red']]}/>
                </section> */}
                <section>
                <DescriptiveSubtitle describeSubtitle= {[props.notes[7], props.notes[8], props.notes[25]]}/>
                </section>
                
                {/* <section>
                <div style= {{justifyContent: 'center'}}>{Banner2()}</div>
                </section> */}
            </div>
        </div>
    )
}

export {NoteTabcontent, NoteTabcontent1};
