import React, {useContext, useEffect} from "react";
import { database } from "./local-database-library/library";
import blogStyle from '../blogstyle.module.css';
import h1Style from "../h1Style.module.css";
// import BlogArticle from "../../USER/grandArticleBackg";
import { Grandheader, Category, BlogArticle, customizedColorContext, authorNameContext } from "../grandPage";
import Picture from "../picture";
import TheLatest from "../latest";
import {Banner, Banner1, Banner2, Banner3, Banner4, Banner5} from '../adsterra';
// import TheLatest from "../latest";
import iran from '../blogPics/iran_flags_small001.jpg';
import bm from '../blogPics/BM.png';

//***************************** */
const HiddenBanner1 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'right'}}>{Banner1()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner4 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner4()}</div>
        </div>
    )
}

//****************************************************** */
const HiddenBanner2 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner2()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner5 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'right'}}>{Banner5()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner3 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner3()}</div>
        </div>
    )
}
//*************************** */
let blogads1 = {
    picture: '',
    ads: <HiddenBanner/>,
    title: '',
    link: '',
    country: '',
    topic: '',
    description: ''
}
let blogads2 = {
    picture: '',
    ads: <HiddenBanner3/>,
    title: '',
    link: '',
    country: '',
    topic: '',
    description: ''
}

//******************************************************** */
export let IranClaimOct7 = {
    picture: iran,
    link: 'http://yorubaindependence.com/global/iran-claims-responsible-for-the-oct-7-attack',
    title: 'Iran claims to be behind October 7 fear assault', // space words
    country: 'world',
    topic: 'Iran claim the Oct 7 attack',
    description: 'It says the attack was a revenge war on Irael and that Israel had to pay the price.'
} 
// console.log(IranClaimOct7.description.toString().length);
let newest = {
    textAlign: "center",
    color: 'white', 
    textDecoration: 'underline',
    backgroundColor: 'black'
}
let relatedStyle = {
    textAlign: "left",
    color: 'black', 
    textDecoration: 'underline'
}
export const IranClaimOct7vsHamas = () => {
    document.title = "Iran profess Oct 7 strike";
    let myMeta = document.createElement('head');
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/55f80087554f1f6b35b39fff65cdb1d5/invoke.js"></script>)
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/9a7e05a8cf5d02916b651253645f6a4f/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/2c48ef1628d6ef1513a522dbad7f282c/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/4827e2f30fe5038127b59b85d910f884/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/8aafcb2b37ff278cbaa3e31f3851cbe6/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/6323653324574e72042ed351bc8075cd/invoke.js"></script>)
    
    // myMeta.innerHTML = (<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8706129627033538"
    // crossorigin="anonymous"></script>);
    myMeta.innerHTML = (<meta name='description' content='It says the attack was a revenge war on Irael and that Israel had to pay the price'></meta>);
    //************************************************************************** */
    let NEWESTLength = 3;
    //************************************************************************** */
    let {authorName} = useContext(authorNameContext);
    let {customizedColor} = useContext(customizedColorContext);
    //***************************************************************************** */
    let globalLatest = database.global_database.slice(0, 3);
    let localLatest = database.local_database.slice(0, 5);
    let latestArticle = globalLatest.concat(localLatest).filter(item => item.link !== IranClaimOct7.link);
    //*********************************************************
    let uselessBlogAdsIndices = [];
    for (let i = 0; i < NEWESTLength; i++) {
        uselessBlogAdsIndices.push(i);
    }
    let usefulBlogAdsIndices = [];
    while (usefulBlogAdsIndices.length < 3) {
        let randomIndices = Math.random() * 10;
        let roundedRandomIndices = Math.round(randomIndices);
        if (roundedRandomIndices >= NEWESTLength) {
            usefulBlogAdsIndices.push(NEWESTLength - 1);
        }
        else {
            usefulBlogAdsIndices.push(uselessBlogAdsIndices[roundedRandomIndices]);
        }
    }
    console.log(uselessBlogAdsIndices + ' useless Blogads indices');
    console.log(usefulBlogAdsIndices + ' useful Blogads indices');
    //********************************************************* */

    latestArticle.splice(usefulBlogAdsIndices[0], 0, blogads1);
    latestArticle.splice(usefulBlogAdsIndices[1], 0, blogads2);
    //**************************************************************************** */
    let globalRelated = database.global_database;
    let localRelated = database.local_database;
    let Articles = globalRelated.concat(localRelated);
    let related1 = Articles.filter(i => { 
        return i.title.includes('Qassem Soleimani')});
    let related2 = Articles.filter(i => { 
        return i.title.includes('Soleimani')});
    let related3 = Articles.filter(i => {
        return i.title.includes('revenge war')});
    let related4 = Articles.filter(i => {
        return i.title.includes('revenge strike')});
    let relatedArticle = related1.concat(related2).concat(related3).concat(related4);
    //********************** */
    let removeDuplicates = relatedArticle.filter((item, index, array) => array.indexOf(item) === index); // this algorithm works in two ways: returns original items without duplicates or returns duplicates/excesses depending on !==/===
    let similarArticles  = removeDuplicates.filter(i => i !== IranClaimOct7);
    
    //*************************test the CSS variables******************************************** */
    let blogHeight = document.querySelector(':root');
    useEffect(() => {
            blogHeight.style.setProperty('--main01', '610px'); //** wearables and co (PL) */
            blogHeight.style.setProperty('--main1', '1050px'); //**iphone 4S(P), iphone 5C(PL) */
            blogHeight.style.setProperty('--main02', '1100px'); //**Iphone 4S (L) */
            blogHeight.style.setProperty('--main2', '1300px'); //**8 iphone 6+ 7+(P) */
            blogHeight.style.setProperty('--main023', '1300px'); //**iphone 6S(P),8 iphone X(P) */
            blogHeight.style.setProperty('--main03', '1660px'); //**iphone 6S(L),8 iphone 6+ 7+(L), galaxy S3(L) */
            blogHeight.style.setProperty('--main3', '1300px'); //**Nexus 6 alt(PL), windows phone(P) */
            blogHeight.style.setProperty('--main4', '1400px'); //*galaxy S3(P), galaxy note 3(PL), galaxy S6(PL), nexus 6P(P) **/
            blogHeight.style.setProperty('--main05', '1650px');  //**nexus 6P(L), windows phone(L), ipad mini(P), ipad air(P) */
            blogHeight.style.setProperty('--main5', '1500px'); //**iphone X(L), ipad 3, 4(P), ipad pro 10.5(P), ipad pro 12.9(P), Kindle fire hd 7(P) */
            blogHeight.style.setProperty('--main6', '1605px'); //**galaxy tab 2(P), galaxy tab S(P) , Laptop(PL)*/
            blogHeight.style.setProperty('--main07', '1790px'); //**galaxy tab 2(L), galaxy tab S(L), Nexus 7(P), Nexus 9(P)*/
            blogHeight.style.setProperty('--main7', '1800px'); //**ipad mini(L), ipad air(L), ipad 3, 4(L), ipad pro 10.5(L), ipad pro 12.9(L), Nexus 7(L), Kindle fire hd 7(L), kindle fire hd 8.9(PL)*/
            blogHeight.style.setProperty('--main8', '2200px'); //**Nexus 9(L), Biggest screen(PL) */
    }, [])
    return (
        <>
            <header>
            <Grandheader mySearch= {['']} theShadow= '1px 1px 8px white inset'  border= '1px solid black' background= "white" logoType= {bm}/>
            <Category cateColor= 'black' input1= "Home" input2= "&#62;" input3= "Politics"/>
            <HiddenBanner5/>
            <div id= {h1Style.grandGrid}>
                <h1 id= {h1Style.grandH1}><b>Iran claims to be behind October 7 fear assault</b></h1>
                <p id= {h1Style.grandH5}></p>
            </div>
            <HiddenBanner1/>
            <Category cateColor= 'rgb(117, 117, 117)' input1= "BY" input2= {authorName} input3= "CREATED 27/12/23"/>
            </header>
            <BlogArticle>
            <section>
                <main>
                    <Picture horizonVertic= {[['block', iran, 'Iran flag'], ['none', '', ''], ['block', customizedColor]]}/>
                    <article>
                        <section>
                        <p className= {blogStyle.blogP}>
                        Iran has attempted to persuade Israel and world, to be behind the assault that killed more than 1,000 of Israelis as a "revenge assault on Israel":
                        </p>
                        </section>
                        <HiddenBanner4/>
                        <section>
                        <p className= {blogStyle.blogP}>
                        It has been a short while, when "Qassem Soleimani", the head of the Guards' elite Quds Force was killed in an attack.
                        Iran has asserted, it was responsible and had planned the strike for the slaughter on Oct. 7; yet, this goal case of vengeance attack was denied by the Hamas group itself as non-credible.
                        </p>
                        </section>
                        <section>
                        <p className= {blogStyle.blogP}>
                        Hamas is known to have been the principal agent for the assault which has prompted the war presently, and can not be left deniable. As for the US logic, it said there was no sign it was Iran liable for the assault or engaged with arranging for the assault.
                        </p>
                        </section>
                        <section>
                        <p className= {blogStyle.blogP}>
                        Hamas has said, the Oct. 7 assault was a reaction to Israelis strikes, obtuse barbarities in Gaza, its hostility toward the Palestine public:
                        This was the case answerable for the assault, and why Hamas needed to strike back at such atrocities and subjugation of Israel, and that Iran doesn't have anything to do with the plan.
                        </p>
                        </section>
                    </article>
                </main>
            </section>
            <section>
                    <aside>
                        <p id= {blogStyle.bigType} style= {newest} className= {blogStyle.blogP}><b><em>Newest</em></b></p>
                        <p id= {blogStyle.smallType} style= {relatedStyle} className= {blogStyle.blogP}><b><em>NEWEST ARTICLES</em></b></p>
                    
                        <TheLatest automateLatest= {[
                        [[NEWESTLength + 2, 'horizontal'], latestArticle.map(i => i.picture), latestArticle.map(i => i.title), latestArticle.map(i => i.link), 'block', latestArticle.map(i => i.ads)],
                        [[''], [''], [''], 'none', latestArticle.map(i => i.ads)],
                        ['rgb(118, 118, 228)', '']]}
                        />
                        
                    </aside>
            </section>
            <section>
                 {/* Similar articles */}
                    <p style= {relatedStyle} className= {blogStyle.blogP}><b><em>SIMILAR ARTICLES</em></b></p>
                    <TheLatest automateLatest= {[
                    [[similarArticles.length, 'horizontal'], similarArticles.map(i => i.picture),similarArticles.map(i => i.title), similarArticles.map(i => i.link), 'block', similarArticles.map(i => i.ads)],
                    [[''], [''], [''], 'none', similarArticles.map(i => i.ads)],
                    ['rgb(118, 118, 228)', '']]}
                    />
            </section>
            </BlogArticle>
        </>
    )
 } 
