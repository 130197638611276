import { Route } from "react-router-dom";
import ErrB from "./ErrBb";
import politics from './blogPics/politics.jpg';
import BRGIEmiddlebelt from './blogPics/Population_density_map_of_Nigerian_states_-_English.png';
import iran from './blogPics/iran_flags_small001.jpg';
import Joe1 from './blogPics/JOE.jpg';
import israel from './blogPics/Flag_of_Israel.svg.png';


let routeX = (
    <>
    {/* <Route exact path= "/" element= {<ErrB theError= "">{}</ErrB>}/> */}
    <Route exact path= "/image" element= {<ErrB theError= "image">{politics}</ErrB>}/>
    <Route exact path= "/BRGIEmiddlebelt" element= {<ErrB theError= "BRGIEmiddlebelt">{BRGIEmiddlebelt}</ErrB>}/>
    <Route exact path= "/iran" element= {<ErrB theError= "iran">{iran}</ErrB>}/>
    <Route exact path= "/Joe1" element= {<ErrB theError= "Joe1">{Joe1}</ErrB>}/>
    <Route exact path= "/israel" element= {<ErrB theError= "israel">{israel}</ErrB>}/>
    </>
)

export default routeX;