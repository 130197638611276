import React, { useEffect, useRef, useState } from 'react';
// import adsterraStyle from './adsterraStyle.module.css';
//****************************************** */
const Banner = () => {
    const getCounter = () => {
        let tmp = 4;
        return tmp++;
    }

    let [toShow, setToShow] = useState(false);
   
    const bannerRef = useRef();
    useEffect(() => {
        const timeOut = getCounter() * 500;
        let timer = setTimeout(() => {
            setToShow(true);
            clearTimeout(timer);
        }, timeOut)
    }, []);
    useEffect(() => {
        if (!toShow) {
            return console.log('ads is not working');
        }
        const atOptions = {
            key: '55f80087554f1f6b35b39fff65cdb1d5',
            format: 'iframe',
            height: 300,
            width: 160,
            params: {}
        };
        if (bannerRef.current && !bannerRef.current.firstChild) {
            const confScript = document.createElement('script');
            const adScript = document.createElement('script');
            adScript.type = 'text/javascript';
            adScript.src = `//www.topcreativeformat.com/${atOptions.key}/invoke.js`;
            confScript.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`
            bannerRef.current.appendChild(confScript);
            bannerRef.current.appendChild(adScript);
        }
    }, [toShow]) 
        return <div ref={bannerRef}/>
};

//************************************************** */

const Banner1 = () => {
    const getCounter = () => {
        let tmp = 8;
        return tmp++;
    }
    
    let [toShow, setToShow] = useState(false);
   
    const bannerRef1 = useRef();
    useEffect(() => {
        
        const timeOut = getCounter() * 500;
        let timer = setTimeout(() => {
            setToShow(true);
            clearTimeout(timer);
        }, timeOut)
    }, []);
    useEffect(() => {
        if (!toShow) {
            return console.log('ads is not working');
        }
        const atOptions = {
            key : '9a7e05a8cf5d02916b651253645f6a4f',
            format : 'iframe',
            height : 60,
            width : 468,
            params : {}
        };
        if (bannerRef1.current && !bannerRef1.current.firstChild) {
            const confScript = document.createElement('script');
            const adScript = document.createElement('script');
            adScript.type = 'text/javascript';
            adScript.src = `//www.topcreativeformat.com/${atOptions.key}/invoke.js`;
            confScript.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`
            bannerRef1.current.appendChild(confScript);
            bannerRef1.current.appendChild(adScript);
        }
    }, [toShow])
    // return <div id= {adsterraStyle.vertical1}><div id= {adsterraStyle.vertical1Adjust} style= {{height: '60px', width: '468px'}} ref={bannerRef1} /></div>;
        return <div ref={bannerRef1} />;
};

//*********************************************************** */

const Banner2 = () => {
    const getCounter = () => {
        let tmp = 14;
        return tmp++;
    }
    
    let [toShow, setToShow] = useState(false);
   
    const bannerRef2 = useRef();
    useEffect(() => {
    
        const timeOut = getCounter() * 500;
        let timer = setTimeout(() => {
            setToShow(true);
            clearTimeout(timer);
        }, timeOut)
    }, []);
    useEffect(() => {
        if (!toShow) {
            return console.log('ads is not working');
        }
        const atOptions = {
            key : '2c48ef1628d6ef1513a522dbad7f282c',
            format : 'iframe',
            height : 250,
            width : 300,
            params : {}
        };
        if (bannerRef2.current && !bannerRef2.current.firstChild) {
            const confScript = document.createElement('script');
            const adScript = document.createElement('script');
            adScript.type = 'text/javascript';
            adScript.src = `//www.topcreativeformat.com/${atOptions.key}/invoke.js`;
            confScript.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`
            bannerRef2.current.appendChild(confScript);
            bannerRef2.current.appendChild(adScript);
        }
    }, [toShow])
        return <div ref={bannerRef2} />;
};

//*****************************************************/

const Banner3 = () => {
    const getCounter = () => {
        let tmp = 18;
        return tmp++;
    }
   
    let [toShow, setToShow] = useState(false);
  
    const bannerRef3 = useRef();
    useEffect(() => {
      
        const timeOut = getCounter() * 500;
        let timer = setTimeout(() => {
            setToShow(true);
            clearTimeout(timer);
        }, timeOut)
    }, []);
    useEffect(() => {
        if (!toShow) {
            return console.log('ads is not working');
        }
        const atOptions = {
            key : '4827e2f30fe5038127b59b85d910f884',
            format : 'iframe',
            height : 600,
            width : 160,
            params : {}
        };
        if (bannerRef3.current && !bannerRef3.current.firstChild) {
            const confScript = document.createElement('script');
            const adScript = document.createElement('script');
            adScript.type = 'text/javascript';
            adScript.src = `//www.topcreativeformat.com/${atOptions.key}/invoke.js`;
            confScript.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`
            bannerRef3.current.appendChild(confScript);
            bannerRef3.current.appendChild(adScript);
        }
    }, [toShow])
        return <div ref={bannerRef3} />;
};
//**************************************************** */

const Banner4 = () => {
    const getCounter = () => {
        let tmp = 25;
        return tmp++;
    }
  
    let [toShow, setToShow] = useState(false);
    
    const bannerRef4 = useRef();
    useEffect(() => {
       
        const timeOut = getCounter() * 500;
        let timer = setTimeout(() => {
            setToShow(true);
            clearTimeout(timer);
        }, timeOut)
    }, []);
    useEffect(() => {
        if (!toShow) {
            return console.log('ads is not working');
        }
        const atOptions = {
            'key' : '8aafcb2b37ff278cbaa3e31f3851cbe6',
            'format' : 'iframe',
            'height' : 50,
            'width' : 320,
            'params' : {}
        };
        if (bannerRef4.current && !bannerRef4.current.firstChild) {
            const confScript = document.createElement('script');
            const adScript = document.createElement('script');
            adScript.type = 'text/javascript';
            adScript.src = `//www.topcreativeformat.com/${atOptions.key}/invoke.js`;
            confScript.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`
            bannerRef4.current.appendChild(confScript);
            bannerRef4.current.appendChild(adScript);
        }
    }, [toShow])
        return <div ref={bannerRef4} />;
};

//***********************j************************************ */

const Banner5 = () => {
    const getCounter = () => {
        let tmp = 32;
        return tmp++;
    }
  
    let [toShow, setToShow] = useState(false);
  
    const bannerRef5 = useRef();
    useEffect(() => {
       
        const timeOut = getCounter() * 500;
        let timer = setTimeout(() => {
            setToShow(true);
            clearTimeout(timer);
        }, timeOut)
    }, []);
    useEffect(() => {
        if (!toShow) {
            return console.log('ads is not working');
        }
        const atOptions = {
            'key' : '6323653324574e72042ed351bc8075cd',
            'format' : 'iframe',
            'height' : 90,
            'width' : 728,
            'params' : {}
        };
        if (bannerRef5.current && !bannerRef5.current.firstChild) {
            const confScript = document.createElement('script');
            const adScript = document.createElement('script');
            adScript.type = 'text/javascript';
            adScript.src = `//www.topcreativeformat.com/${atOptions.key}/invoke.js`;
            confScript.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`
            bannerRef5.current.appendChild(confScript);
            bannerRef5.current.appendChild(adScript);
        }
    }, [toShow])
        return <div ref={bannerRef5} />;
};



export {Banner, Banner1, Banner2, Banner3, Banner4, Banner5};

