import { Mamza$FGN } from "../mamza and Gunmi";
import { Canada$Aid } from "../Canada reserves $152m";
import { nigerDelta$wike } from "../Ijaw country-Fabura-wike";
import { NLCprotest } from "../NLC protest and Imo gov";
import { KgCharlesVsKenya } from "../king charles apology kenyan";
import { AutonomyInNg } from "../autonomyInNgByOlisa";
import { HouseOfRep } from "../houseOfRep-yacht";
import { GermanyVsColonial } from "../GermanyVsTanzania";
import { NgDebt } from "../NgGovtDept";
import { ElijahVsEcowas } from "../ElijahAyodeleVsEcowas";
import { Rwanda } from "../RwandaVsVisafree";
import { IgbohoVsNorthernConsusGroup } from "../FulaniAndSunday";
import { USVsLodging } from "../USAAdvisesOnLodging";
import { PutinVsTreaty } from "../PutinAndNuclearTreaty";
import { DonaldVsIsrael } from "../DonaldJVsIsraelHaters";
import { UktravelAdvisory } from "../UktravelAdvisoryImoKogi";
import { CanadaAdvisory } from "../canadaTravelAdvisory";
import { NorthKoreaShutembassy } from "../northKoreaShtEmbassy";
import { KyivHit } from "../kyivHitSince 52days";
import { TinubuAttendsJAES } from "../TinubuattendsJAES";
import {AloyEjimakorVsNGN} from "../MNKstillheldIllegally";
import {NigeriaRestructure} from "../NigeriaNeedRestructureSeyiMakin";
import { AdelekeVs } from "../adelekeVsStateJudge";
import { JoeAnd } from "../joe biden and marijuana";
import { BRGIEandmiddlebelt } from "../BRGIEANDMiddlebelt";
import { IranAndIsrael } from "../top iranian officer killed";
import { IsraelEmbassy } from "../IsraelEmbassyAttacked";
import { IranClaimOct7 } from "../iran claim oct 7";
import { MNKAndOwen } from "../MNK and Owen Nwokolo";
import { RussiaEmbassy } from "../Russia reopen embassy in burkina";
import { FranceDeploy } from "../francefestivesecurity";
import { TurkeyAnd } from "../turkeyarrestedsuspects";
import { RussiaIntensify } from "../ukraine russia war continue";
import { KosovoVisafree } from "../kosovoVisafree";
import { SamAdeyemiOn } from "../SamAdeyemionChannels";
import { FranceCloses } from "../franceShutitsEmbassy";
import { TrumpMigration } from "../donaldTrumpmigrationpolicy";
import { UNToSanctionIran } from "../USwantIranSanctHousthi";
import { RussiaNeed } from "../RussiaNeedCombatants";
import { WissamalTawil } from "../Hezbolla major killed";
import { PatUtomiOn } from "../Pat Utomi and Nigeria Not working";
import { HouthisFired } from "../houthis hit US ship";
import { NigeriansSuffering } from "../Nigerians suffering sharia";
import { HouthisTerrorGroup } from "../houthis terrorist group";
import { Theconstitution } from "../restructure ng Olisa 1999 constitution";
import { The21st } from "../Restructure Ng CYNDAC";
import { AdewoleAdebayo } from "../Adewole Adebayo insecurity";
import { BidenCall } from "../palestine sovereignity";
import { SupremeAnd} from "../supreme court delay CTC";
import { SanwooluAnd} from "../lagosPensionIncrease";
import { SupremeRelease} from "../supreme court release kanu ctc";
import {RussiaPlane} from "../russia plane crashed killed ukriainian";
import {EUTwo} from "../EU two states";
import {GaniAdamsRestructure} from "../true federalism gani";
import {UNRWA12} from "../allegation on uNRWA";
import {SeyiMakindeOn} from "../insecurity solution - makinde";
import {Inconsiderate} from "../inconsiderate Kings";
import {IranDoesNotBack} from "../iran has no backing";
import {IsraelStrikesR} from "../israel attack rafah";
import {TrumpOnDefence} from "../trump advises NATO countries defence";
import {Iran45th} from "../iran mark 45 annivasry";
import {IraqSeeksMilitary} from "../iraq allie with us";
import {OshiomholeAndTinubu} from "../nigerian suffering by lukman";
import {LukmanQueryTinubu} from "../osiomhole on buhari policy";
import {BayeroAdvises} from "../hardship by bayaro";
import {UKusJointStrike} from "../UK and US dealthy throws in Hodeida.js";
import {JoeBidenOnBorderSecurity} from "../Joe Biden and border.js";
import {Globalrightsindex} from "../Nigeria world rights rating.js";
import {TERRORISTSattackRChurh} from "../terrorists attack russia.js";
import {gunBan} from "../USA considers gun ban.js";
import {minimumPReview} from "../NLC NASS NILDS on minimum pay.js";
import {statepolice} from "../state police by Bode George.js";
//************************************************************ */
export let database = {
    local_database: [
        statepolice,
        minimumPReview,
        Globalrightsindex,
        BayeroAdvises,
        LukmanQueryTinubu,
        OshiomholeAndTinubu,
        Inconsiderate,
        SeyiMakindeOn,
        GaniAdamsRestructure,
        SupremeRelease,
        SanwooluAnd,
        SupremeAnd,
        AdewoleAdebayo,
        The21st,
        Theconstitution,
        NigeriansSuffering,
        PatUtomiOn,
        SamAdeyemiOn,
        MNKAndOwen,
        BRGIEandmiddlebelt,
        AdelekeVs,
        NigeriaRestructure,
        AloyEjimakorVsNGN,
        TinubuAttendsJAES,
        CanadaAdvisory,
        UktravelAdvisory,
        Mamza$FGN,
        Canada$Aid,
        nigerDelta$wike,
        NLCprotest,
        AutonomyInNg,
        HouseOfRep,
        NgDebt,
        ElijahVsEcowas,
        IgbohoVsNorthernConsusGroup
    ],
    global_database: [
        gunBan,
        TERRORISTSattackRChurh,
        JoeBidenOnBorderSecurity,
        UKusJointStrike,
        IraqSeeksMilitary,
        Iran45th,
        TrumpOnDefence,
        IsraelStrikesR,
        IranDoesNotBack,
        UNRWA12,
        EUTwo,
        RussiaPlane,
        BidenCall,
        HouthisTerrorGroup,
        HouthisFired,
        WissamalTawil,
        RussiaNeed,
        UNToSanctionIran,
        TrumpMigration,
        FranceCloses,
        KosovoVisafree,
        RussiaIntensify,
        TurkeyAnd,
        FranceDeploy,
        RussiaEmbassy,
        IranClaimOct7,
        IsraelEmbassy,
        IranAndIsrael,
        JoeAnd,
        KyivHit,
        NorthKoreaShutembassy,
        DonaldVsIsrael,
        PutinVsTreaty,
        GermanyVsColonial,
        KgCharlesVsKenya,
        Rwanda,
        USVsLodging
    ]
}
