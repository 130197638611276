import React, {useContext, useEffect} from "react";
import { database } from "./local-database-library/library";
import blogStyle from '../blogstyle.module.css';
import h1Style from "../h1Style.module.css";
import { Grandheader, Category, BlogArticle, customizedColorContext, authorNameContext } from "../grandPage";
import Picture from "../picture";
import TheLatest from "../latest";
import {Banner, Banner1, Banner2, Banner3, Banner4, Banner5} from '../adsterra';
import pat1 from '../blogPics/Pat-Utomi-on-Restructuring-KOKO-TV-NG-5.jpg';
import pat2 from '../blogPics/Pat_utomi_1.jpg';
import bm from '../blogPics/BM.png';
//***************************************** */
const HiddenBanner1 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'right'}}>{Banner1()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner4 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner4()}</div>
        </div>
    )
}

//****************************************************** */
const HiddenBanner2 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner2()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner5 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'right'}}>{Banner5()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner3 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner3()}</div>
        </div>
    )
}
//*************************** */
let blogads1 = {
    picture: '',
    ads: <HiddenBanner/>,
    title: '',
    link: '',
    country: '',
    topic: '',
    description: ''
}
let blogads2 = {
    picture: '',
    ads: <HiddenBanner3/>,
    title: '',
    link: '',
    country: '',
    topic: '',
    description: ''
}

//**********************f********************************** */
export let PatUtomiOn = {
    picture: pat1,
    link: 'http://yorubaindependence.com/local/Pat-utomi-on-arise-tv',
    title: 'Nigeria, not a country that thinks about the people - Pat Utomi on Arise television', // space words
    country: 'Nigeria',
    topic: 'Pat Utomi',
    description: 'Pat Utomi talks on matters arising from Nigeria a country with politcal tension, need to build on political parties'
} 
// console.log(PatUtomiOn.description.toString().length);
let newest = {
    textAlign: "center",
    color: 'white', 
    textDecoration: 'underline',
    backgroundColor: 'black'
}
let relatedStyle = {
    textAlign: "left",
    color: 'black', 
    textDecoration: 'underline'
}
export const PatUtomiOnNigeria = () => {
    document.title = "Nigeria, not a country that thinks about the people - Pat Utomi on Arise television";
    let myMeta = document.createElement('head');
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/55f80087554f1f6b35b39fff65cdb1d5/invoke.js"></script>)
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/9a7e05a8cf5d02916b651253645f6a4f/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/2c48ef1628d6ef1513a522dbad7f282c/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/4827e2f30fe5038127b59b85d910f884/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/8aafcb2b37ff278cbaa3e31f3851cbe6/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/6323653324574e72042ed351bc8075cd/invoke.js"></script>)
    // myMeta.innerHTML = (<script type='text/javascript' src='//pl22243447.toprevenuegate.com/87/60/03/876003e3d87e7227f2dfbfaa0ddbf61a.js'></script>)
    // myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/55f80087554f1f6b35b39fff65cdb1d5/invoke.js"></script>)
    // myMeta.innerHTML = (<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8706129627033538"
    // crossorigin="anonymous"></script>);
    myMeta.innerHTML = (<meta name='description' content='Pat Utomi talks on matters arising from Nigeria a country with politcal tension, need to build on political parties'></meta>);
    //*********************************** */
    let NEWESTLength = 5;
    //**************************************************************************/
    
    let {authorName} = useContext(authorNameContext);
    let {customizedColor} = useContext(customizedColorContext);
    
    //***************************************************************************** */
    let globalLatest = database.global_database.slice(0, 3);
    let localLatest = database.local_database.slice(0, 5);
    let latestArticle = globalLatest.concat(localLatest).filter(item => item.link !== PatUtomiOn.link);
    //*********************************************************
    //*********************************************************
    let uselessBlogAdsIndices = [];
    for (let i = 0; i < NEWESTLength; i++) {
        uselessBlogAdsIndices.push(i);
    }
    let usefulBlogAdsIndices = [];
    while (usefulBlogAdsIndices.length < 3) {
        let randomIndices = Math.random() * 10;
        let roundedRandomIndices = Math.round(randomIndices);
        if (roundedRandomIndices >= NEWESTLength) {
            usefulBlogAdsIndices.push(NEWESTLength - 1);
        }
        else {
            usefulBlogAdsIndices.push(uselessBlogAdsIndices[roundedRandomIndices]);
        }
    }
    latestArticle.splice(usefulBlogAdsIndices[0], 0, blogads1);
    latestArticle.splice(usefulBlogAdsIndices[1], 0, blogads2);
    //**************************************************************************** */
    let globalRelated = database.global_database;
    let localRelated = database.local_database;
    let Articles = globalRelated.concat(localRelated);
    let related1 = Articles.filter(i => { 
        return i.title.includes('Pat Utomi')});
    let related2 = Articles.filter(i => { 
        return i.title.includes('restructure')});
    let related3 = Articles.filter(i => {
        return i.title.includes('not a working country')});
    // let related4 = Articles.filter(i => {
    //     return i.title.includes('')});
    let relatedArticle = related1.concat(related2).concat(related3);
    //********************** */
    let removeDuplicates = relatedArticle.filter((item, index, array) => array.indexOf(item) === index); // this algorithm works in two ways: returns original items without duplicates or returns duplicates/excesses depending on !==/===
    let similarArticles  = removeDuplicates.filter(i => i !== PatUtomiOn);
    //************************************************************** */
    //********************************************************************* */
  
    return (
        <>
             <header>
            <Grandheader mySearch= {['']} theShadow= '1px 1px 8px white inset'  border= '1px solid black' background= "white" logoType= {bm}/>
            <Category cateColor= 'black' input1= "Home" input2= "&#62;" input3= "Politics"/>
            <div id= {h1Style.grandGrid}>
                <h1 id= {h1Style.grandH1}><b>Nigeria, not a country that thinks about the people - Pat Utomi on Arise television</b></h1>
                <p id= {h1Style.grandH5}>Pat Utomi talks on matters arising from Nigeria a country with politcal tension, need to build on political parties</p>
            </div>
            <HiddenBanner5/>
            <HiddenBanner1/>
            
            <Category cateColor= 'rgb(117, 117, 117)' input1= "BY" input2= {authorName} input3= "CREATED 09/01/24"/>
            </header>
            <BlogArticle>
            <section>
                <main>
                
                    <Picture horizonVertic= {[['none','', ''], ['block', pat2, 'Pat Utomi'], ['block', customizedColor]]}/>
                    <article>
                        <p className= {blogStyle.blogP}>
                        Pat Utomi had considered matters emerging from Nigeria, and the frantic circumstance in Nigeria. He gave a remarkable talk on the best way to get away from the grim economy influencing Nigerians, and the condition of improving and developing the country.
                        </p>
                    
                        <p className= {blogStyle.blogP}>
                        He had said this during the interview on Arise TV, that the Nigerian politicians are responsible for all the wreck that is going on in the country, is it the corruption we witness on each event or the violent elections; Nigeria has been participating in pattern of vicious elections without appropriately constructing political parties.
                        He said, politicians are not really working to the greatest advantage of each and every people living in the country, but working for themselves. That, Nigeria is what it is today since politicians are answerable for the difficulties each nigerians do confront.
                        </p>
                        <p className= {blogStyle.blogP}>
                        The joblessness, frailty, he said, civil servants who work seven days a week really work do not accomplish anything on the grounds that the governmental issues of Nigeria isn't organized to perform to see the improvement of all, at the same time, the development of the country, politicians who are chosen by these individuals, are the party that benefits the outcome, to share and loot cash for themselves.
                        </p>
                       
                        <HiddenBanner2/>
                        <p className= {blogStyle.blogP}>
                        He said, "Nigeria is certainly not a working country." "Nigeria has no structure, other than the structure politicians had made, to plunder and share cash, to deal and play party politics, grabbing power, engaging in violent elections."
                        </p>
                        <p className= {blogStyle.blogP}>
                        He said, elections and picking representatives after every 4 years will not address the country's unjustifiable structure, but, that building and encouraging political parties will solve issues.
                        Pat Utomi laid emphasis on building and tolerating as numerous political parties which can address the people feelings and realities in the overall population, building a society of individuals that have confidence in specific values and reforms. Then elections can follow.
                        He said, "there is no political party in Nigeria that exists"
                        </p>
                
                        <p className= {blogStyle.blogP}>
                        Pat reviewed on the merging of the parties of PDP and LP:
                        He said, the coalition doesn't make any difference, or picking the delegate of such coalition.

                        </p>
                        <HiddenBanner4/>
                    
                    </article>
                </main>
            </section>
            <section>
                    <aside>
                        <p id= {blogStyle.bigType} style= {newest} className= {blogStyle.blogP}><b><em>Newest</em></b></p>
                        <p id= {blogStyle.smallType} style= {relatedStyle} className= {blogStyle.blogP}><b><em>NEWEST ARTICLES</em></b></p>
                    
                        <TheLatest automateLatest= {[
                        [[NEWESTLength + 2, 'horizontal'], latestArticle.map(i => i.picture), latestArticle.map(i => i.title), latestArticle.map(i => i.link), 'block', latestArticle.map(i => i.ads)],
                        [[''], [''], [''], 'none', latestArticle.map(i => i.ads)],
                        ['rgb(118, 118, 228)', '']]}
                        />
                        
                    </aside>
            </section>
            <section>
                 {/* Similar articles */}
                    <p style= {relatedStyle} className= {blogStyle.blogP}><b><em>SIMILAR ARTICLES</em></b></p>
                    <TheLatest automateLatest= {[
                    [[similarArticles.length, 'horizontal'], similarArticles.map(i => i.picture),similarArticles.map(i => i.title), similarArticles.map(i => i.link), 'block', similarArticles.map(i => i.ads)],
                    [[''], [''], [''], 'none', similarArticles.map(i => i.ads)],
                    ['rgb(118, 118, 228)', '']]}
                    />
            </section>
            </BlogArticle>
        </>
    )
 } 
