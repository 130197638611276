import React from "react";
import blogStyle from './blogstyle.module.css';
import {Grandheader, Category} from "./grandPage";
import bm from './blogPics/BM.png';
import { TheSocial, TheLinking } from "./footer";
import Menu from "./Menu";


//***************************************/
let blogFooterStyle = {
    backgroundColor: 'white',
    color: 'black'
}
//***************************************/


const Privacy = () => {
    document.title = "Privacy BM";
    let myMeta = document.createElement('head');
    myMeta.innerHTML = (<script type='text/javascript' src='//pl22243447.toprevenuegate.com/87/60/03/876003e3d87e7227f2dfbfaa0ddbf61a.js'></script>);
    // myMeta.innerHTML = (<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8706129627033538"
    // crossorigin="anonymous"></script>);
    myMeta.innerHTML = (<meta name='description' content='BM privacy page'></meta>);
    //******************************************************** */
    let linkedIn = (
        <img alt="LinkedIn URL" src="https://img.shields.io/twitter/url?label=About&logo=LinkedIn&logoColor=blue&style=social&url=https%3A%2F%2FLinkedIn.io"/>
    )

    let twitter = (
        <img alt="Twitter URL" src="https://img.shields.io/twitter/follow/OlutobiOlusesi?label=Twitter&logo=Twitter&style=social"/>
    )

    let gmail = (
        <img alt="Gmail URL" src="https://img.shields.io/twitter/url?label=Gmail&logo=Gmail&style=social&url=https%3A%2F%2Fgmail.io"/>
    )
    //*****************h********test the CSS variables******************************************** */
    function RADS() {
        let adsHttp = new XMLHttpRequest();
        adsHttp.open("GET", "googlefc.callbackQueue.push(googlefc.showRevocationMessage)", "async")
        adsHttp.send();
    }
    return (
        <>
            <header>
            <Grandheader mySearch= {['']} theShadow= '1px 1px 8px white inset'  border= '1px solid black' background= "white" logoType= {bm}/>
            <Category cateColor= 'black' input1= "Home" input2= "&#62;" input3= "Politics"/>
            </header>
            <section>
                <article>
                    <p className= {blogStyle.blogP}>
                        Branch Medium is a Blog and a Promotion Upheld web app. The Interface you see on the blog site connect to readers and collect no data or store data whether through sign up or subscription. Likewise;
                    </p>
                    <p className= {blogStyle.blogP}>
                        Google ads on the blog collect no data or store cache. If it may however store any cache: this is the consent of users click on google ads for subscription to personalized ads, to help readers with more good contents.
                    </p>
                    <p className= {blogStyle.blogP}>
                        Ads permission to show ads on screen will hereby be shown, in the EEA region.
                    </p>
                    <p className= {blogStyle.blogP}>
                        If you want to unsubscribe from personalized ads you had recently consented to, you can <span id= {blogStyle.revokeAd} style= {{color: 'green'}} onClick= {RADS}>click on revoke consent</span>.
                    </p>
                
                    <p className= {blogStyle.blogP}>
                        This Assistance might contain connections to different locales. Assuming you click on
                        an outsider connection, you will be coordinated to that site. Note
                        that these outside locales are not worked by me.
                        In this way, I emphatically encourage you to review the
                        Privacy Policy of this site. I have
                        zero power over and assume no responsibility for the content, 
                        protection arrangements, or practices of any outsider destinations or
                        administrations.
                    </p>
                    <h2 className= {blogStyle.blogh2}>COOKIES</h2>
                    <p className= {blogStyle.blogP}>
                        Cookies are documents with a modest quantity of information that are
                        generally utilized as unknown exceptional identifiers. These are sent
                        to your program from the sites that you visit and are
                        put away on your gadget's interior memory.
                    </p> 
                    <p className= {blogStyle.blogP}>
                        This service doesn't utilize these "cookies" unequivocally. However,
                        this web application might utilize outsider code and libraries that uses
                        "cookies" to gather data and work on their administrations.
                        You have the choice to either acknowledge or decline these cookies
                        what's more, know when a cookie is being shipped off your gadget.
                    </p>
                    <h2 className= {blogStyle.blogh2}>DATA</h2>
                    <p className= {blogStyle.blogP}>
                        I need to illuminate you that at whatever point you
                        utilize my Administration, I gather no information and data.
                    </p>
                </article>
            </section>
            <footer>
                <Menu options= {['', '', '']} links= {['', '']} bC= 'white' ne= 'block'/>
                <TheLinking/>
                <TheSocial socialOnOff= 'block' mySocial= {[linkedIn, gmail, twitter]} myTwoStyle= {blogFooterStyle}/>
            </footer>
        </>
    )
}
export default Privacy; 