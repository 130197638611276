import React, {useContext, useEffect} from "react";
import { database } from "./local-database-library/library";
import h1Style from "../h1Style.module.css";
import blogStyle from '../blogstyle.module.css'
// import BlogArticle from "../../USER/grandArticleBackg";
import { Grandheader, Category, BlogArticle, customizedColorContext, authorNameContext } from "../grandPage";
import Picture from "../picture";
import TheLatest from "../latest";
// import TheLatest from "../latest";
import Jamie from '../blogPics/ado-9-1.png';
import {Banner, Banner1, Banner2, Banner3, Banner4, Banner5} from '../adsterra';
import bm from '../blogPics/BM.png';
import battle49 from '../blogPics/battle49.jpg';

const HiddenBanner1 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'right'}}>{Banner1()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner4 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner4()}</div>
        </div>
    )
}

//****************************************************** */
const HiddenBanner2 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner2()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner5 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'right'}}>{Banner5()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner3 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner3()}</div>
        </div>
    )
}
//*************************** */
let blogads1 = {
    picture: '',
    ads: <HiddenBanner/>,
    title: '',
    link: '',
    country: '',
    topic: '',
    description: ''
}
let blogads2 = {
    picture: '',
    ads: <HiddenBanner3/>,
    title: '',
    link: '',
    country: '',
    topic: '',
    description: ''
}


//**********************f***********************************/
export let Canada$Aid = {
    picture: Jamie,
    link: 'http://yorubaindependence.com/local/canada-aid',
    title: 'Jamie Christoff with Edu Betta in a discussion to provide aid to tackle threats and danger to life , Poverty eradication , Humanitarian crisis , current crisis in Nigeria',
    country: 'Nigeria',
    topic: 'Canada humanitarian support to Nigeria',
    description: 'The current state of the country crisis has called the attention of the canadian government to provide the support the country men need. However, the proportion will be decided by the government.'
}
// console.log(Canada$Aid.description.toString().length);
let newest = {
    textAlign: "center",
    color: 'white',
    textDecoration: 'underline',
    backgroundColor: 'black'
}
let relatedStyle = {
    textAlign: "left",
    color: 'black', 
    textDecoration: 'underline'
}
//**************************** */
export const CanadaAidSupport = () => {
    document.title = 'Jamie Christoff met with Edu Betta in a discussion';
    let myMeta = document.createElement('head');
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/55f80087554f1f6b35b39fff65cdb1d5/invoke.js"></script>)
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/9a7e05a8cf5d02916b651253645f6a4f/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/2c48ef1628d6ef1513a522dbad7f282c/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/4827e2f30fe5038127b59b85d910f884/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/8aafcb2b37ff278cbaa3e31f3851cbe6/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/6323653324574e72042ed351bc8075cd/invoke.js"></script>)
    
    // myMeta.innerHTML = (<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8706129627033538"
    // crossorigin="anonymous"></script>);
    myMeta.innerHTML = (<meta name='description' content='Canada Aid to Nigeria 1/11/23'></meta>);
    //***************************************************************************** */
    let NEWESTLength = 2;
    //*********************** */
    let {authorName} = useContext(authorNameContext);
    let {customizedColor} = useContext(customizedColorContext);
    let globalLatest = database.global_database.slice(0, 3);
    let localLatest = database.local_database.slice(0, 5);
    let latestArticle = globalLatest.concat(localLatest).filter(item => item.link !== Canada$Aid.link);
    //*********************************************************
    let uselessBlogAdsIndices = [];
    for (let i = 0; i < NEWESTLength; i++) {
        uselessBlogAdsIndices.push(i);
    }
    let usefulBlogAdsIndices = [];
    while (usefulBlogAdsIndices.length < 3) {
        let randomIndices = Math.random() * 10;
        let roundedRandomIndices = Math.round(randomIndices);
        if (roundedRandomIndices >= NEWESTLength) {
            usefulBlogAdsIndices.push(NEWESTLength - 1);
        }
        else {
            usefulBlogAdsIndices.push(uselessBlogAdsIndices[roundedRandomIndices]);
        }
    }
    console.log(uselessBlogAdsIndices + ' useless Blogads indices');
    console.log(usefulBlogAdsIndices + ' useful Blogads indices');
    //********************************************************* */

    latestArticle.splice(usefulBlogAdsIndices[0], 0, blogads1);
    latestArticle.splice(usefulBlogAdsIndices[1], 0, blogads2);
    //*********************************************************************** */
    let globalRelated = database.global_database;
    let localRelated = database.local_database;
    let Articles = globalRelated.concat(localRelated);
    let related1 = Articles.filter(i => { 
        return i.title.includes('Jamie Christoff')});
    let related2 = Articles.filter(i => { 
        return i.title.includes('Canadian')});
    let related3 = Articles.filter(i => {
        return i.title.includes('Edu Betta')});
    let related4 = Articles.filter(i => {
        return i.title.includes('Canada')});
    let relatedArticle = related1.concat(related2).concat(related3).concat(related4);
    //********************** */
    let removeDuplicates = relatedArticle.filter((item, index, array) => array.indexOf(item) === index); // this algorithm works in two ways: returns original items without duplicates or returns duplicates/excesses depending on !==/===
    let similarArticles  = removeDuplicates.filter(i => i !== Canada$Aid);

    //************************************* */
    let blogHeight = document.querySelector(':root');
    useEffect(() => {
            blogHeight.style.setProperty('--main01', '500px'); //** wearables and co (PL) */
            blogHeight.style.setProperty('--main1', '750px'); //**iphone 4S(P), iphone 5C(PL) */
            blogHeight.style.setProperty('--main02', '870px'); //**Iphone 4S (L) */
            blogHeight.style.setProperty('--main2', '1000px'); //**8 iphone 6+ 7+(P) */
            blogHeight.style.setProperty('--main023', '1000px'); //**iphone 6S(P),8 iphone X(P) */
            blogHeight.style.setProperty('--main03', '1360px'); //**iphone 6S(L),8 iphone 6+ 7+(L), galaxy S3(L) */
            blogHeight.style.setProperty('--main3', '1000px'); //**Nexus 6 alt(PL), windows phone(P) */
            blogHeight.style.setProperty('--main4', '1100px'); //*galaxy S3(P), galaxy note 3(PL), galaxy S6(PL), nexus 6P(P) **/
            blogHeight.style.setProperty('--main05', '1350px');  //**nexus 6P(L), windows phone(L), ipad mini(P), ipad air(P) */
            blogHeight.style.setProperty('--main5', '1200px'); //**iphone X(L), ipad 3, 4(P), ipad pro 10.5(P), ipad pro 12.9(P), Kindle fire hd 7(P) */
            blogHeight.style.setProperty('--main6', '1205px'); //**galaxy tab 2(P), galaxy tab S(P) , Laptop(PL)*/
            blogHeight.style.setProperty('--main07', '1390px'); //**galaxy tab 2(L), galaxy tab S(L), Nexus 7(P), Nexus 9(P)*/
            blogHeight.style.setProperty('--main7', '1300px'); //**ipad mini(L), ipad air(L), ipad 3, 4(L), ipad pro 10.5(L), ipad pro 12.9(L), Nexus 7(L), Kindle fire hd 7(L), kindle fire hd 8.9(PL)*/
            blogHeight.style.setProperty('--main8', '1600px'); //**Nexus 9(L), Biggest screen(PL) */
    }, [])
    return (
        <>
            <header>
            <Grandheader mySearch= {['']} theShadow= '1px 1px 8px white inset'  border= '1px solid black' background= "white" logoType= {bm}/>
            <HiddenBanner1/>
            <Category cateColor= 'black' input1= "Home" input2= "&#62;" input3= "Politics"/>
            <div id= {h1Style.grandGrid}>
                <h1 id= {h1Style.grandH1}><b>The Canadian govt reserves $18m for Nigeria to ease the current crisis</b></h1>
                <p id= {h1Style.grandH5}>Jamie Christoff met with Edu Betta in a discussion to provide aid, from the Canadian goverment, to tackle threats and danger to life, Poverty eradication, Humanitarian crisis, current crisis in Nigeria</p>
            </div>
            
            <Category cateColor= 'rgb(117, 117, 117)' input1= "BY" input2= {authorName} input3= "CREATED 1/11/23"/>
            </header>
            <HiddenBanner5/>
            <BlogArticle>
            <section>
                <main>
                <HiddenBanner4/>
                    <Picture horizonVertic= {[['block', Jamie, 'Jamie Christoff'], ['none', '', ''], ['block', customizedColor]]}/>
                    <article>
                       <section>
                       <p className= {blogStyle.blogP}>
                        The Canadian ambassador to Nigeria, Jamie Christoff, in a meeting with the Minister for Humanitarian Affairs and Poverty Alleviation Dr Betta Edu, at her office in Abuja, said his nation is willing to give $18m million as a component of its help to the country for different projects to ease destitution and decrease compassionate emergencies in Nigeria.
                        </p>
                       </section>
                       <HiddenBanner2/>
                       <section>
                       <p className= {blogStyle.blogP}>
                        Edu educated the High commissioner that the ministry of Humanitarian Affairs will guarantee the meeting emissary effort and that the funding will be utilized to address the crisis in the country and issues of neediness in Nigeria, saying its application will be in a straightforward way to lift million of Nigerians out of Poverty.
                        </p>
                       </section>
                        <section>
                        <p className= {blogStyle.blogP}>
                            The reserve will be very much effective to cushion the inflation, rise of goods price, and rise of fuel. Lately, the goverment removed subsidy to save cost because the country was in debt, also, the cause had been that the country was in recession.
                        </p>
                        </section>
                      
                    
                    </article>
                </main>
            </section>
            <section>
                    <aside>
                        <p id= {blogStyle.bigType} style= {newest} className= {blogStyle.blogP}><b><em>Newest</em></b></p>
                        <p id= {blogStyle.smallType} style= {relatedStyle} className= {blogStyle.blogP}><b><em>NEWEST ARTICLES</em></b></p>
                    
                        <TheLatest automateLatest= {[
                        [[NEWESTLength + 2, 'horizontal'], latestArticle.map(i => i.picture), latestArticle.map(i => i.title), latestArticle.map(i => i.link), 'block', latestArticle.map(i => i.ads)],
                        [[''], [''], [''], 'none', latestArticle.map(i => i.ads)],
                        ['rgb(118, 118, 228)', '']]}
                        />
                        
                    </aside>
            </section>
            <section>
                 {/* Similar articles */}
                    <p style= {relatedStyle} className= {blogStyle.blogP}><b><em>SIMILAR ARTICLES</em></b></p>
                    <TheLatest automateLatest= {[
                    [[similarArticles.length, 'horizontal'], similarArticles.map(i => i.picture),similarArticles.map(i => i.title), similarArticles.map(i => i.link), 'block', similarArticles.map(i => i.ads)],
                    [[''], [''], [''], 'none', similarArticles.map(i => i.ads)],
                    ['rgb(118, 118, 228)', '']]}
                    />
            </section>
            </BlogArticle>
        </>
    )
 } 