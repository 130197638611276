import React, {useContext, useEffect} from "react";
import { database } from "./local-database-library/library";
import blogStyle from '../blogstyle.module.css';
import h1Style from "../h1Style.module.css";
// import BlogArticle from "../../USER/grandArticleBackg";
import { Grandheader, Category, BlogArticle, customizedColorContext, authorNameContext } from "../grandPage";
import Picture from "../picture";
import {Banner, Banner1, Banner2, Banner3, Banner4, Banner5} from '../adsterra';
import TheLatest from "../latest";
import KingCharles from '../blogPics/King_Charles_III.jpg';
import KingCharles3 from '../blogPics/King_Charles_3.jpg';
import bm from '../blogPics/BM.png';
//***************************** */
const HiddenBanner1 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'right'}}>{Banner1()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner4 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner4()}</div>
        </div>
    )
}

//****************************************************** */
const HiddenBanner2 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner2()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner5 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'right'}}>{Banner5()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner()}</div>
        </div>
    )
}
//****************************************************** */
const HiddenBanner3 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{Banner3()}</div>
        </div>
    )
}
//*************************** */
let blogads1 = {
    picture: '',
    ads: <HiddenBanner/>,
    title: '',
    link: '',
    country: '',
    topic: '',
    description: ''
}
let blogads2 = {
    picture: '',
    ads: <HiddenBanner3/>,
    title: '',
    link: '',
    country: '',
    topic: '',
    description: ''
}

//**********************f********************************** */
export let KgCharlesVsKenya = {
    picture: KingCharles3,
    link: 'http://yorubaindependence.com/global/king-charles-apologies-to-kenyan',
    title: 'An apology to the people of Kenya in contrary to the crime done against humanity',
    country: 'Kenya',
    topic: 'Britain apologies to Kenya',
    description: 'Britain and Kenya establish reconciliation in the talk, returnment of artifacts and colonial items back to Kenya.'
}
let newest = {
    textAlign: "center",
    color: 'white', 
    textDecoration: 'underline',
    backgroundColor: 'black'
}
let relatedStyle = {
    textAlign: "left",
    color: 'black', 
    textDecoration: 'underline'
}
export const KgCharlesVsKenyaVsApology = () => {
    document.title = "An apology to the people of Kenya";
    let myMeta = document.createElement('head');
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/55f80087554f1f6b35b39fff65cdb1d5/invoke.js"></script>)
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/9a7e05a8cf5d02916b651253645f6a4f/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/2c48ef1628d6ef1513a522dbad7f282c/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/4827e2f30fe5038127b59b85d910f884/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/8aafcb2b37ff278cbaa3e31f3851cbe6/invoke.js"></script>);
    myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/6323653324574e72042ed351bc8075cd/invoke.js"></script>)
    
    // myMeta.innerHTML = (<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8706129627033538"
    // crossorigin="anonymous"></script>);
    myMeta.innerHTML = (<meta name='description' content='King Charles III visit Kenya for Britain colonial atrocities against Kenyan'></meta>);
    let NEWESTLength = 3;
    //************************************************************************** */
    let {authorName} = useContext(authorNameContext);
    let {customizedColor} = useContext(customizedColorContext);
    //***************************************************************************** */
    let globalLatest = database.global_database.slice(0, 3);
    let localLatest = database.local_database.slice(0, 5);
    let latestArticle = globalLatest.concat(localLatest).filter(item => item.link !== KgCharlesVsKenya.link);
    //*********************************************************
    let uselessBlogAdsIndices = [];
    for (let i = 0; i < NEWESTLength; i++) {
        uselessBlogAdsIndices.push(i);
    }
    let usefulBlogAdsIndices = [];
    while (usefulBlogAdsIndices.length < 3) {
        let randomIndices = Math.random() * 10;
        let roundedRandomIndices = Math.round(randomIndices);
        if (roundedRandomIndices >= NEWESTLength) {
            usefulBlogAdsIndices.push(NEWESTLength - 1);
        }
        else {
            usefulBlogAdsIndices.push(uselessBlogAdsIndices[roundedRandomIndices]);
        }
    }
    console.log(uselessBlogAdsIndices + ' useless Blogads indices');
    console.log(usefulBlogAdsIndices + ' useful Blogads indices');
    //********************************************************* */

    latestArticle.splice(usefulBlogAdsIndices[0], 0, blogads1);
    latestArticle.splice(usefulBlogAdsIndices[1], 0, blogads2);
    //**************************************************************************** */
    let globalRelated = database.global_database;
    let localRelated = database.local_database;
    let Articles = globalRelated.concat(localRelated);
    let related1 = Articles.filter(i => { 
        return i.title.includes('king Charles III')});
    let related2 = Articles.filter(i => {
        return i.title.includes('Kenya')});
    let related3 = Articles.filter(i => {
        return i.title.includes('colonial')});
    let relatedArticle = related1.concat(related2).concat(related3);
    //********************** */
    let removeDuplicates = relatedArticle.filter((item, index, array) => array.indexOf(item) === index); // this algorithm works in two ways: returns original items without duplicates or returns duplicates/excesses depending on !==/===
    let similarArticles  = removeDuplicates.filter(i => i !== KgCharlesVsKenya);
    
    //*************************test the CSS variables******************************************** */
    
    return (
        <>
            <header>
            <Grandheader mySearch= {['']} theShadow= '1px 1px 8px white inset'  border= '1px solid black' background= "white" logoType= {bm}/>
            <Category cateColor= 'black' input1= "Home" input2= "&#62;" input3= "Politics"/>
            <div id= {h1Style.grandGrid}>
                <h1 id= {h1Style.grandH1}><b>King Charles III visit Kenya for Britain colonial atrocities against Kenyan</b></h1>
                <p id= {h1Style.grandH5}>An apology to the people of Kenya in contrary to the crime done against humanity</p>
            </div>
            <HiddenBanner5/>
            <HiddenBanner1/>
           
            <Category cateColor= 'rgb(117, 117, 117)' input1= "BY" input2= {authorName} input3= "CREATED 1/11/23"/>
            </header>
            <BlogArticle>
            <section>
                <main>
                <HiddenBanner4/>
                    <article>
                        <section>
                        <p className= {blogStyle.blogP}>
                        King Charles III and Queen Camilla went to the country of Kenya for a 4 day visit to address people of the Kenyan country about the missteps, barbarities, menaces done to them during the times of Britain colonial rule, a statement of regret that should be conveyed to the Kenyan nation for a protected and favored future between the two countries.
                        </p>
                        </section>
                        <Picture horizonVertic= {[['none', KingCharles, 'King Charles III'], ['block', KingCharles, 'King Charles III'], ['block', customizedColor]]}/>
                        <section>
                        <p className= {blogStyle.blogP}>
                        He said in statements "there can be no excuse." for the atrocities carried out.
                        He shed all the more light on Britain former invasion and suppressive rule on the Kenyan nation as improper, scornful, obtrusive. What's more, a state of regrets run the show.
                        </p>
                        </section>
                        <HiddenBanner2/>
                        <section>
                        <p className= {blogStyle.blogP}>
                        He said in statements "The negative impacts of colonization are still being felt to date, and are being passed from generation to generation"
                        </p>
                        </section>
                    </article>
                </main>
            </section>
            <section>
                    <aside>
                        <p id= {blogStyle.bigType} style= {newest} className= {blogStyle.blogP}><b><em>Newest</em></b></p>
                        <p id= {blogStyle.smallType} style= {relatedStyle} className= {blogStyle.blogP}><b><em>NEWEST ARTICLES</em></b></p>
                    
                        <TheLatest automateLatest= {[
                        [[NEWESTLength + 2, 'horizontal'], latestArticle.map(i => i.picture), latestArticle.map(i => i.title), latestArticle.map(i => i.link), 'block', latestArticle.map(i => i.ads)],
                        [[''], [''], [''], 'none', latestArticle.map(i => i.ads)],
                        ['rgb(118, 118, 228)', '']]}
                        />
                        
                    </aside>
            </section>
            <section>
                 {/* Similar articles */}
                    <p style= {relatedStyle} className= {blogStyle.blogP}><b><em>SIMILAR ARTICLES</em></b></p>
                    <TheLatest automateLatest= {[
                    [[similarArticles.length, 'horizontal'], similarArticles.map(i => i.picture),similarArticles.map(i => i.title), similarArticles.map(i => i.link), 'block', similarArticles.map(i => i.ads)],
                    [[''], [''], [''], 'none', similarArticles.map(i => i.ads)],
                    ['rgb(118, 118, 228)', '']]}
                    />
            </section>
            </BlogArticle>
        </>
    )
 } 