import React from "react";
import pictureStyle from "./picture.module.css";
// import Bs from './pics/battle11.jpg';
// import Homepic from "./pics/city.jpg";


// let verticalImage = {
//     backgroundImage: Homepic,
//     display: props.vertical
// }
// let horizontalImage = {
//     backgroundImage: Bs,
//     display: props.horizontal
// }

const Picture = (props) => {
    let figColor = {
        color: props.horizonVertic[2][1]
    } 
    return (
        <section>
            <div style= {{display: props.horizonVertic[2][0]}} className= {pictureStyle.pictureGrid}>
            <div id= {pictureStyle.pictureFlex}>
                <span></span>
                <figure style= {{display: props.horizonVertic[0][0]}} className= {pictureStyle.picture}>
                    <div style={{backgroundImage: `url(${props.horizonVertic[0][1]})`}} className= {pictureStyle.horizontal}></div>
                    <figcaption style={figColor}>{props.horizonVertic[0][2]}</figcaption>
                </figure>
                <figure style= {{display: props.horizonVertic[1][0]}} className= {pictureStyle.picture}>
                    <div style={{backgroundImage: `url(${props.horizonVertic[1][1]})`}} className= {pictureStyle.vertical}></div>
                    <figcaption style={figColor}>{props.horizonVertic[1][2]}</figcaption>
                </figure>
                <span></span>
            </div>
        </div>
        </section>
    )
}
export default Picture
