import React from "react";
import { Grandheader, Category, NavigateArticles } from "./grandPage";
import TheLatest from "./latest";
import { database } from "./posts/local-database-library/library";
// import ty from './blogPics/Toloka.jpg';
import battle49 from './blogPics/battle49.jpg';
import bm from './blogPics/BM.png';
import { SearchTab } from "./grandPage";
// import Menu from "./Menu";
import {Banner, Banner1, Banner2, Banner3, Banner4, Banner5} from './adsterra';
import { TheSocial, TheLinking } from "./footer";
import LandingStyle from "./LandingPage.module.css";
// import Picture from "./picture";
//*********************************************************/
let linkedIn = (
    <img alt="LinkedIn URL" src="https://img.shields.io/twitter/url?label=linkedIn&logo=LinkedIn&logoColor=blue&style=social&url=https%3A%2F%2FLinkedIn.io"/>
)

let twitter = (
    <img alt="Twitter URL" src="https://img.shields.io/twitter/follow/OlutobiOlusesi?label=Twitter&logo=Twitter&style=social"/>
)

let gmail = (
    <img alt="Gmail URL" src="https://img.shields.io/twitter/url?label=Gmail&logo=Gmail&style=social&url=https%3A%2F%2Fgmail.io"/>
)
//***************************************/
let blogFooterStyle = {
    backgroundColor: 'white',
    color: 'black'
}
//****************************************************** */
const CallBanner1 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'right'}}>{<Banner1/>}</div>
            
        </div>
    )
}
//************************* */
const CallBanner3 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{<Banner3/>}</div>
        </div>
    )
}
//*******************************************************/
const CallBanner2 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{<Banner2/>}</div>
        </div>
    )
}
//************** */
const CallBanner5 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{<Banner5/>}</div>
        </div>
    )
}
//*************** */
const CallBanner = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
            <div style= {{textAlign: 'center'}}>{<Banner/>}</div>
        </div>
    )
}
//*************** */
const CallBanner4 = () => {
    return (
        <div style= {{display: 'grid', overflowX: 'hidden', overflowY: 'hidden'}}>
        <div style= {{textAlign: 'center'}}>{<Banner4/>}</div>
    </div>
    )
}
//********************************************** */
let ads1 = {
    picture: '',
    ads: <CallBanner2/>,
    link: '',
    title: '',
    country: '',
    topic: '',
    description: ''
}
let ads2 = {
    picture: '',
    ads: <CallBanner4/>,
    link: '',
    title: '',
    country: '',
    topic: '',
    description: ''
}

//**************************************** */


class GlobalPolitics extends React.Component {
    constructor() {
        super();
        this.state = {
            databaseGlobal: database.global_database,
            searchField: '',
            nextConstant: '',
            x: 0,
            y: 10,
            firstNavigatorDisplay: '',
            lastNavigatorDisplay: '',
            previousNavigatorDisplay: '',
            nextNavigatorDisplay: '',
            databaseGlobalLength: database.global_database.length,
            th10: 10,
            count: 1,
            firstStyle: '',
            nextStyle: '',
            previousStyle: '',
            lastStyle: '',
            reinstate: ''
        }
    }
    //************************* */
    onSearchChange = (e) => {
        this.setState({...this.state, searchField: e.target.value});
    }
    //************************/
    firstNavigation = () => {
        return this.setState({...this.state, x: 0, y: 10, databaseGlobalLength: database.global_database.length, count: 1 , firstNavigatorDisplay: 'none', nextNavigatorDisplay: 'block', previousNavigatorDisplay: 'none', lastNavigatorDisplay: 'block', nextStyle: {backgroundC: '', textC: ''}, previousStyle: {backgroundC: '', textC: ''}, firstStyle: {backgroundC: 'rgb(134, 134, 134)', textC: 'white'}, lastStyle: {backgroundC: '', textC: ''}})
    }
    nextNavigation = () => {
        let times10x = Math.floor(database.global_database.length / 10); // x position in counts
        let theRemainderL = database.global_database.length % 10; // last items less than 10 or 0
        console.log(`${times10x} time10x`);
        console.log(`${this.state.nextConstant} nextcontant`);
        console.log(`${theRemainderL} theremainderL`);
        if (this.state.databaseGlobalLength >= 10) {
            return this.setState({...this.state, x: this.state.x + 10, y: this.state.y + 10, databaseGlobalLength: this.state.databaseGlobalLength - 10, nextConstant: false, firstNavigatorDisplay: 'block', nextNavigatorDisplay: 'block', previousNavigatorDisplay: 'block', count: this.state.count + 1, nextStyle: {backgroundC: 'rgb(134, 134, 134)', textC: 'white'}, previousStyle: {backgroundC: '', textC: ''}, firstStyle: {backgroundC: '', textC: ''}, lastStyle: {backgroundC: '', textC: ''}});
        }
        else if (this.state.databaseGlobalLength < 10 & this.state.databaseGlobalLength > 0) {
            return this.setState({...this.state, x: this.state.x + 10, y: this.state.y + theRemainderL, count: this.state.count + 1, nextConstant: true, nextNavigatorDisplay: 'none', previousNavigatorDisplay: 'block', lastNavigatorDisplay: 'none', firstNavigatorDisplay: 'block', nextStyle: {backgroundC: '', textC: ''}, previousStyle: {backgroundC: '', textC: ''}, firstStyle: {backgroundC: '', textC: ''}, lastStyle: {backgroundC: 'rgb(134, 134, 134)', textC: 'white'}});
        }
        else if (this.state.databaseGlobalLength === 0 || this.state.nextConstant === true) {
            return this.setState({...this.state, y: times10x * 10, x: (times10x * 10) - 10, count: times10x + 1, nextConstant: false, nextNavigatorDisplay: 'none', previousNavigatorDisplay: 'block', lastNavigatorDisplay: 'none', firstNavigatorDisplay: 'block', nextStyle: {backgroundC: '', textC: ''}, previousStyle: {backgroundC: '', textC: ''}, firstStyle: {backgroundC: '', textC: ''}, lastStyle: {backgroundC: 'rgb(134, 134, 134)', textC: 'white'}});
        }
    }
    previousNavigation = () => {
        if (this.state.reinstate === false) {
            return this.setState({...this.state, reinstate: true, y: this.state.x, x: this.state.x - 10, firstNavigatorDisplay: 'block', databaseGlobalLength: this.state.databaseGlobalLength + 10, previousNavigatorDisplay: 'block', nextNavigatorDisplay: 'block', lastNavigatorDisplay: 'block', count: this.state.count - 1, nextStyle: {backgroundC: '', textC: ''}, previousStyle: {backgroundC: 'rgb(134, 134, 134)', textC: 'white'}, firstStyle: {backgroundC: '', textC: ''}, lastStyle: {backgroundC: '', textC: ''}})// y is previous value of x
        }
        else if (this.state.databaseGlobalLength !== database.global_database.length) {
            return this.setState({...this.state, x: this.state.x - 10, y: this.state.y - 10, firstNavigatorDisplay: 'block', databaseGlobalLength: this.state.databaseGlobalLength + 10, previousNavigatorDisplay: 'block', nextNavigatorDisplay: 'block', lastNavigatorDisplay: 'block', count: this.state.count - 1, nextStyle: {backgroundC: '', textC: ''}, previousStyle: {backgroundC: 'rgb(134, 134, 134)', textC: 'white'}, firstStyle: {backgroundC: '', textC: ''}, lastStyle: {backgroundC: '', textC: ''}});
        }
        else if (this.state.databaseGlobalLength === database.global_database.length) {
            return this.setState({...this.state, x: 0, y: 10, databaseGlobalLength: database.global_database.length, count: 1 , firstNavigatorDisplay: 'none', nextNavigatorDisplay: 'block', previousNavigatorDisplay: 'none', lastNavigatorDisplay: 'block', nextStyle: {backgroundC: '', textC: ''}, previousStyle: {backgroundC: '', textC: ''}, firstStyle: {backgroundC: 'rgb(134, 134, 134)', textC: 'white'}, lastStyle: {backgroundC: '', textC: ''}});
        }
    }
    lastNavigation = () => {
        let times10 = Math.floor(database.global_database.length / 10); // x position in counts
        let theRemainder = database.global_database.length % 10; // last items less than 10 or 0
        console.log(`${times10} time10`);
        console.log(`${theRemainder} theremainder`);
        if (theRemainder !== 0) {
            return this.setState({...this.state, x: times10 * 10, y: theRemainder + times10 * 10, databaseGlobalLength: theRemainder, count: times10 + 1, nextNavigatorDisplay: 'none', previousNavigatorDisplay: 'block', lastNavigatorDisplay: 'none', firstNavigatorDisplay: 'block', reinstate: false, nextStyle: {backgroundC: '', textC: ''}, previousStyle: {backgroundC: '', textC: ''}, firstStyle: {backgroundC: '', textC: ''}, lastStyle: {backgroundC: 'rgb(134, 134, 134)', textC: 'white'}});
        }
        else {
            return this.setState({...this.state, y: times10 * 10, x: (times10 * 10) - 10, databaseGlobalLength: 0, count: times10 + 1, nextNavigatorDisplay: 'none', previousNavigatorDisplay: 'block', lastNavigatorDisplay: 'none', firstNavigatorDisplay: 'block', nextStyle: {backgroundC: '', textC: ''}, previousStyle: {backgroundC: '', textC: ''}, firstStyle: {backgroundC: '', textC: ''}, lastStyle: {backgroundC: 'rgb(134, 134, 134)', textC: 'white'}});
        }
    }
    //**************************/
    componentDidMount() {
        if (this.state.databaseGlobalLength < 10) {
            return this.setState({...this.state, firstNavigatorDisplay: 'none', previousNavigatorDisplay: 'none', lastNavigatorDisplay: 'none', nextNavigatorDisplay: 'none'});
        }
    }
    
    //*************************** */
    firstDisplay() {
        return this.state.firstNavigatorDisplay;
    }
    nextDisplay() {
        return this.state.nextNavigatorDisplay;
    }
    previousDisplay() {
        return this.state.previousNavigatorDisplay;
    }
    lastDisplay() {
        return this.state.lastNavigatorDisplay;
    }
    countDisplay() {
        return this.state.count;
    }
    //************************/
    render() {
        document.title = 'BM Politics News | global';
        let myMeta = document.createElement('head');
        myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/55f80087554f1f6b35b39fff65cdb1d5/invoke.js"></script>)
        myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/9a7e05a8cf5d02916b651253645f6a4f/invoke.js"></script>);
        myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/2c48ef1628d6ef1513a522dbad7f282c/invoke.js"></script>);
        myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/4827e2f30fe5038127b59b85d910f884/invoke.js"></script>);
        myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/8aafcb2b37ff278cbaa3e31f3851cbe6/invoke.js"></script>);
        myMeta.innerHTML = (<script async= "async" data-cfasync= "false" src="//www.topcreativeformat.com/6323653324574e72042ed351bc8075cd/invoke.js"></script>)
        // myMeta.innerHTML = (<script type='text/javascript' src='//pl22243447.toprevenuegate.com/87/60/03/876003e3d87e7227f2dfbfaa0ddbf61a.js'></script>)
        // myMeta.innerHTML = (<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8706129627033538"
        // crossorigin="anonymous"></script>);
        myMeta.innerHTML = (<meta name='description' content='|global politics News|'></meta>);
        //************************************************************************* */
        const filteredArticles = this.state.databaseGlobal.filter(i => {
            return i.title.toString().toLowerCase().includes(this.state.searchField.toString().toLowerCase())});
        console.log(`${filteredArticles} filter`);
        
        //******************************************************************* */
        let arrayPictureG = '';
        let arrayTitleG = '';
        let arrayLinkG = '';
        let arrayAdsG = '';
        //****************************** */
       
        filteredArticles.forEach((i, index, array) => arrayPictureG += `${i.picture.toString()}$`);
        filteredArticles.forEach((i, index, array) => arrayTitleG += `${i.title.toString()}$`);
        filteredArticles.forEach((i, index, array) => arrayLinkG += `${i.link.toString()}$`);
        filteredArticles.forEach((i, index, array) => arrayAdsG += `${i.ads}$`);
       
        //**************************** */
        
        // .splice(usefulAdsIndices[0], 0, ads1.picture).splice(usefulAdsIndices[1], 0, ads2.picture).splice(usefulAdsIndices[2], 0, ads3.picture)
        // .splice(usefulAdsIndices[0], 0, ads1.title).splice(usefulAdsIndices[1], 0, ads2.title).splice(usefulAdsIndices[2], 0, ads3.title)
        // .splice(usefulAdsIndices[0], 0, ads1.link).splice(usefulAdsIndices[1], 0, ads2.link).splice(usefulAdsIndices[2], 0, ads3.link)
        let navigatedArrayPicG = Array.from(arrayPictureG.split("$")).slice(this.state.x, this.state.y);
        let navigatedArrayTitleG = Array.from(arrayTitleG.split("$")).slice(this.state.x, this.state.y);
        let navigatedArrayLinkG = Array.from(arrayLinkG.split("$")).slice(this.state.x, this.state.y);
        let navigatedArrayAdsG = Array.from(arrayAdsG.split("$")).slice(this.state.x, this.state.y);
        // console.log(`${navigatedArrayPicG[8]} numberofItemsAtFirstMount`);
        console.log(`${this.state.th10} th10`);
        console.log(`${this.state.x} x`);
        console.log(`${this.state.databaseGlobalLength} databaseGlobalLength`);
        console.log(`${this.state.nextNavigatorDisplay} this.state.nextNavigatorDisplay`);
        console.log(`${this.state.previousNavigatorDisplay} this.state.previousNavigatorDisplay`);

        //****************** */
        let uselessAdsIndices = [];
        for (let i = this.state.x; i <= this.state.y; i++) {
            uselessAdsIndices.push(i);
        }
        let usefulAdsIndices = [];
        while (usefulAdsIndices.length < 3) {
            let randomIndices = Math.random() * 10;
            let roundedRandomIndices = Math.round(randomIndices);
            usefulAdsIndices.push(uselessAdsIndices[roundedRandomIndices]);
        }
        console.log(usefulAdsIndices + ' useful ads indices');
        
        //************************* */
        
        navigatedArrayPicG.splice(usefulAdsIndices[0], 0, ads1.picture);
        navigatedArrayPicG.splice(usefulAdsIndices[1], 0, ads2.picture);
       
        //*************** */
        navigatedArrayTitleG.splice(usefulAdsIndices[0], 0, ads1.title);
        navigatedArrayTitleG.splice(usefulAdsIndices[1], 0, ads2.title);
        
        //***************** */
        navigatedArrayLinkG.splice(usefulAdsIndices[0], 0, ads1.link);
        navigatedArrayLinkG.splice(usefulAdsIndices[1], 0, ads2.link);
        
        //**************************** */
        navigatedArrayAdsG.splice(usefulAdsIndices[0], 0, ads1.ads);
        navigatedArrayAdsG.splice(usefulAdsIndices[1], 0, ads2.ads);
        // navigatedArrayAdsG.map
       // console.log(`${typeof JSON.parse(navigatedArrayAdsG[0])} typeof undefined`);

        return (
            <>
                <header>
                <Grandheader mySearch= {[<SearchTab passedDownSearchValues= {[this.onSearchChange, this.firstNavigation]}/>]} theShadow= '1px 1px 8px white inset'  border= '1px solid black' background= "white" logoType= {bm}/>
                <CallBanner1/>
                <Category cateColor= 'black' input1= "Home" input2= "&#62;" input3= "Politics"/>
                </header>
                <NavigateArticles display= {[this.countDisplay(), this.nextDisplay(), this.previousDisplay(), this.state.nextStyle, this.state.previousStyle, this.state.firstNavigatorDisplay, this.state.lastNavigatorDisplay, this.state.firstStyle, this.state.lastStyle]} 
                navigation= {[this.firstNavigation, this.nextNavigation, this.previousNavigation, this.lastNavigation]}/>
                <section className= {LandingStyle.LandingPageGrid}>
                    <CallBanner5/>
                    <main>
                    <TheLatest automateLatest= {[
                        [[navigatedArrayPicG.length, 'horizontal'], navigatedArrayPicG, navigatedArrayTitleG, navigatedArrayLinkG, 'block', navigatedArrayAdsG],
                        [[battle49, battle49, battle49, battle49, battle49, battle49, battle49, battle49], ['Sunday and that Igboho', 'from Benin Republic', 'to declare Yoruba',  'Nation Sunday Igboho', 'will be back from Benin Republic', 'yes he will have been made', 'yar old man', 'you are in this job'], ['', '', '', '', '', '', '', ''], 'none', navigatedArrayAdsG], 
                        ['green', '']]}
                    />
                    </main>
                    <CallBanner/>
                    {/* <CallBanner3/> */}
                </section>
                
                <footer>
                <TheLinking/>
                <TheSocial socialOnOff= 'block' mySocial= {[linkedIn, gmail, twitter]} myTwoStyle= {blogFooterStyle}/>
                </footer>
                
            </>
        )
    }
}
//********************************************* */

export default GlobalPolitics