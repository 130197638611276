import React from "react";
import blogStyle from './blogstyle.module.css';
import {Grandheader, Category} from "./grandPage";
import bm from './blogPics/BM.png';
import { TheSocial, TheLinking } from "./footer";
import Menu from "./Menu";


//***************************************/
let blogFooterStyle = {
    backgroundColor: 'white',
    color: 'black'
}
//***************************************/


const About = () => {
    document.title = "About BM";
    let myMeta = document.createElement('head');
    myMeta.innerHTML = (<script type='text/javascript' src='//pl22283200.toprevenuegate.com/64/24/05/64240599871cce71a658981da110d019.js'></script>);
    // myMeta.innerHTML = (<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8706129627033538"
    // crossorigin="anonymous"></script>);
    myMeta.innerHTML = (<meta name='description' content='BM about page'></meta>);
    //******************************************************** */
    let linkedIn = (
        <img alt="LinkedIn URL" src="https://img.shields.io/twitter/url?label=About&logo=LinkedIn&logoColor=blue&style=social&url=https%3A%2F%2FLinkedIn.io"/>
    )

    let twitter = (
        <img alt="Twitter URL" src="https://img.shields.io/twitter/follow/OlutobiOlusesi?label=Twitter&logo=Twitter&style=social"/>
    )

    let gmail = (
        <img alt="Gmail URL" src="https://img.shields.io/twitter/url?label=Gmail&logo=Gmail&style=social&url=https%3A%2F%2Fgmail.io"/>
    )
    //*************************test the CSS variables******************************************** */
    
    return (
        <>
            <header>
            <Grandheader mySearch= {['']} theShadow= '1px 1px 8px white inset'  border= '1px solid black' background= "white" logoType= {bm}/>
            <Category cateColor= 'black' input1= "Home" input2= "&#62;" input3= "Politics"/>
            </header>
            <section>
                <article>
                    <p className= {blogStyle.blogP}>
                        Olutobi Olusesi constructed the Branch medium web application as
                        a Promotion Upheld web application. This Assistance is given by
                        Olutobi Olusesi at no expense and is planned for use as
                        is.
                    </p>
                    <p className= {blogStyle.blogP}>
                        This website categorically publise on Politics within and beyond site location. As you can see on this website, every article has been written on politics, political leaders, the government and the state of political affairs of a country, continent and abroad. 
                        
                    </p>
                </article>
            </section>
            <footer>
                <Menu options= {['', '', '']} links= {['', '']} bC= 'white' ne= 'block'/>
                <TheLinking/>
                <TheSocial socialOnOff= 'block' mySocial= {[linkedIn, gmail, twitter]} myTwoStyle= {blogFooterStyle}/>
            </footer>
        </>
    )
} 
export default About;